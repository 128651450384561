import { makeStyles, colors } from '@barracuda-internal/bds-core'

export default makeStyles(theme => ({
  headerWrapper: {
    padding: theme.spacing(2)
  },
  searchWrapper: {
    marginTop: theme.spacing(1)
  },
  searchTextFieldWrapper: {
    minWidth: 220,
    marginTop: theme.spacing(1)
  },
  searchTextField: {
    backgroundColor: theme.palette.common.white,
    width: '100%',
    flex: '1 1 auto'
  },
  contentWrapper: {
    backgroundColor: colors.scaleGray025,
    padding: theme.spacing(3),
    flex: 1,
    flexDirection: 'column',
    marginBottom: theme.spacing(3)
  },
  card: {
    backgroundColor: colors.white,
    padding: theme.spacing(3),
    flex: '0 0 auto',
    width: '100%',
    '&.spacing': {
      marginTop: theme.spacing(2)
    }
  },
  caption: {
    color: colors.scaleGray500
  },
  row: {
    marginTop: theme.spacing(2)
  },
  chipRow: {
    marginTop: theme.spacing(1)
  },
  chip: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
    background: colors.deepOcean025
  },
  icon: {
    marginRight: theme.spacing(1)
  },
  bdsIcon: {
    marginRight: theme.spacing(1),
    color: colors.scaleGray600
  },
  disabled: {
    color: `${colors.scaleGray300} !important`
  },
  disabledChips: {
    opacity: 0.6
  },
  menuSpacing: {
    marginTop: theme.spacing(1)
  }
}))
