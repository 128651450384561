import axios from 'axios'
import { v4 as makeUuid } from 'uuid'
import {
  IEmailLogApiClient,
  IEmailLogGetEmailDetailsRequest,
  IEmailLogGetEmailDetailsResponse,
  IEmailLogSearchParams,
  IEmailLogSearchResponse
} from '@barracuda-internal/converged-email-log'

export const emailLogApi = axios.create()

export interface IEmailLogApiClientArgs {
  accessToken: string
}

export const EmailLogApiClient = ({ accessToken }: IEmailLogApiClientArgs): IEmailLogApiClient => {
  emailLogApi.defaults.headers.common.Authorization = `Bearer ${accessToken}`

  const search =
    (abortController: AbortController) =>
    async (params: IEmailLogSearchParams): Promise<IEmailLogSearchResponse> => {
      const { bcc_account_id, ...queryParams } = params
      const res = await emailLogApi.get(`/external/v1/email-log/${bcc_account_id}/search`, {
        signal: abortController.signal,
        params: queryParams,
        headers: {
          'X-Request-ID': makeUuid()
        }
      })
      return res.data
    }

  const getEmailDetails =
    (abortController: AbortController) =>
    async (request: IEmailLogGetEmailDetailsRequest): Promise<IEmailLogGetEmailDetailsResponse> => {
      const { bcc_account_id, message_id, origin } = request
      const res = await emailLogApi.get(`/external/v1/email-log/${bcc_account_id}/email/${message_id}`, {
        signal: abortController.signal,
        params: {
          origin
        },
        headers: {
          'X-Request-ID': makeUuid()
        }
      })
      return res.data
    }

  return Object.freeze({
    search,
    getEmailDetails
  })
}
