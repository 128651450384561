import { destroySplitSdk, getTreatments, initSplitSdk, selectTreatmentValue } from '@splitsoftware/splitio-redux'

import config from 'config/appConfig'
import reduxStore from 'lib/reduxStore'
import { FEATURES } from 'lib/splitioFeatures'

let savedAccountId: string | undefined
export type Features = (typeof FEATURES)[keyof typeof FEATURES]

export interface InitSplitio {
  accountId: string
  userId: string
}

export enum SplitioStatuses {
  on = 'on',
  off = 'off'
}

export function checkSplitioFeatures(attributes: InitSplitio) {
  reduxStore.dispatch(getTreatments({ splitNames: [...Object.values(FEATURES)], attributes }))
}

export function initSplitio({ accountId, userId }: InitSplitio) {
  const store = reduxStore.getState()
  const isAlreadyInitialized = !!Object.keys(store?.splitio?.treatments || {}).length

  if (accountId && (savedAccountId !== accountId || !isAlreadyInitialized)) {
    savedAccountId = accountId
    const sdkConfig: any = {
      core: {
        authorizationKey: config.ENVIRONMENT.IS_DEV && !config.SPLITIO.API_KEY ? 'localhost' : config.SPLITIO.API_KEY,
        key: accountId,
        trafficType: 'account'
      },
      features: {
        // use this feature values for 'localhost'
        [FEATURES.EGD_React_Overview_Rollout]: SplitioStatuses.on,
        [FEATURES.EGD_React_End_User_Rollout]: SplitioStatuses.on,
        [FEATURES.EGD_React_License_Compliance_Rollout]: SplitioStatuses.on,
        [FEATURES.EGD_React_Language_Selector_Rollout]: SplitioStatuses.on,
        [FEATURES.EGD_Temporary_Passcode_Rollout]: SplitioStatuses.on,
        [FEATURES.EGD_React_Okta_Rollout]: SplitioStatuses.on,
        [FEATURES.EGD_Email_Banners_Rollout]: SplitioStatuses.on,
        [FEATURES.EGD_React_India_Deployment]: SplitioStatuses.on,
        [FEATURES.EGD_React_Reporting_Rollout]: SplitioStatuses.on,
        [FEATURES.EGD_React_Scheduled_Reports]: SplitioStatuses.on,
        [FEATURES.EGD_React_Admin_Threat_Origins]: SplitioStatuses.off,
        [FEATURES.EGD_Inline_Deployment_Rollout]: SplitioStatuses.off
      }
    }

    reduxStore.dispatch(initSplitSdk({ config: sdkConfig, onReady: () => checkSplitioFeatures({ userId, accountId }) }))
  }
}

export function isMyFeatureOn(feature: string): boolean {
  const splitStore = reduxStore.getState().splitio
  const isFeatureOn = selectTreatmentValue(splitStore, feature) === SplitioStatuses.on

  return isFeatureOn
}

export function destroySplitio() {
  if (savedAccountId) {
    reduxStore.dispatch(destroySplitSdk())
    savedAccountId = undefined
  }
}
