import { createAsyncThunk } from '@reduxjs/toolkit'
import { objectToCamel } from 'ts-case-convert'
import restClient, { ApiRejectResponse, validateApiError } from 'lib/api/restClient'
import apiRoutes from 'lib/api/apiRoutes'
import { RootState } from 'redux/toolkit/store'
import { DomainsInDB } from 'types/redux/user/UserTypes'
import {
  ALL_DOMAINS,
  BarracudaReportTypes,
  CustomReport,
  paginatedReports,
  RelativeDateRanges,
  Report,
  ReportsList,
  ReportsResponse,
  ScheduledReport
} from 'types/reports'
import { setErrorSnackBar, setSuccessSnackBar } from 'redux/features/app/appSlice'

export interface GetReportPayload {
  id: string
  type: BarracudaReportTypes
  reportIsChanged: boolean
  paginated?: boolean
}

export interface DownloadReportPayload extends GetReportPayload {
  format: GetReportFormat
  label: string
}
export interface DownloadReportResponse {
  content: string
  contentType: string
  name: string
}

export enum GetReportFormat {
  CSV = 'CSV',
  JSON = 'JSON'
}

export type SetCustomReportsListPayload = CustomReport[]
export type SetCustomReportsListResponse = { items: CustomReport[] }
export type SetPinnedReportIdPayload = string
export type SetPinnedReportIdResponse = { id: string }

export function createReportParams(reportId: BarracudaReportTypes, rootState: RootState): Report {
  const { reports, auth, user } = rootState
  const columnFilters = Object.values(reports.filters.columnFilters).filter(columnFilter => !!columnFilter)

  const result: Report = {
    reportId,
    startDate: null,
    endDate: null,
    ...(!reports.filters.relativeDateRange && {
      startDate: reports.filters.startDate,
      endDate: reports.filters.endDate
    }),
    interval: reports.filters.interval,
    relativeDateRange: reports.filters.relativeDateRange,
    accountId: auth.accessTokenObject?.accountId || '',
    domainIds: reports.filters.domainIds.includes(ALL_DOMAINS)
      ? (user.availableDomains || []).map((domain: DomainsInDB) => domain.domainId)
      : reports.filters.domainIds,
    filters: columnFilters.length ? columnFilters : null,
    direction: reports.filters.direction,
    listTop: reports.filters.listTop
  }

  if (paginatedReports.includes(reportId)) {
    result.page = reports.filters.page - 1
    result.pageSize = reports.filters.pageSize
  }

  return result
}

export interface BaseScheduledReportPayload {
  relative_date_range: RelativeDateRanges
  scheduled_frequency: string
  scheduled_day_of_month?: string | undefined
  scheduled_day_of_week?: number | null
  scheduled_specific_date?: number | null
  recipients: string[]
  timezone: string
  enabled: boolean
}

export interface ScheduleReportPayload extends BaseScheduledReportPayload {
  bcc_id: string
  account_id: string
  report_id: string
  domain_ids: number[]
  format?: string
}

export interface UpdateScheduledReportPayload extends Partial<BaseScheduledReportPayload> {
  id: string[]
}

export interface DeleteScheduledReportPayload {
  report_id: string
}

export const getReportsList = createAsyncThunk<ReportsList, undefined, ApiRejectResponse>(
  'REPORTS/getList',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_REPORTS_LIST)
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const getReport = createAsyncThunk<ReportsResponse, GetReportPayload, ApiRejectResponse>(
  'REPORTS/getReport',
  async (payload, { rejectWithValue, getState }) => {
    try {
      const apiParams = createReportParams(payload.type, getState() as RootState)
      const route = payload.paginated ? apiRoutes.GET_PAGINATED_REPORT : apiRoutes.GET_REPORT
      const resp = await restClient(route, {
        data: { filters: apiParams },
        headers: {
          isToolkitCompatible: true
        }
      })
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const downloadReport = createAsyncThunk<DownloadReportResponse, DownloadReportPayload, ApiRejectResponse>(
  'REPORTS/downloadReport',
  async (payload, { rejectWithValue, getState, dispatch }) => {
    try {
      const apiParams = createReportParams(payload.type, getState() as RootState)
      const route = payload.paginated ? apiRoutes.GET_PAGINATED_REPORT : apiRoutes.GET_REPORT
      const resp = await restClient(route, {
        data: { filters: { ...apiParams, format: payload.format || GetReportFormat.CSV } },
        headers: {
          isToolkitCompatible: true
        }
      })

      return { ...resp.data, name: payload.label }
    } catch (e) {
      dispatch(setErrorSnackBar({ message: 'error_download_report' }))
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const setCustomReportsList = createAsyncThunk<
  SetCustomReportsListResponse,
  SetCustomReportsListPayload,
  ApiRejectResponse
>('REPORTS/setCustomReportsList', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.SET_CUSTOM_REPORTS, {
      data: { items: payload },
      headers: {
        isToolkitCompatible: true
      }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})

export const setPinnedReportId = createAsyncThunk<
  SetPinnedReportIdResponse,
  SetPinnedReportIdPayload,
  ApiRejectResponse
>('REPORTS/setPinnedReportId', async (payload, { rejectWithValue }) => {
  try {
    const resp = await restClient(apiRoutes.SET_PINNED_REPORT_ID, {
      data: { id: payload },
      headers: {
        isToolkitCompatible: true
      }
    })

    return resp.data
  } catch (e) {
    return rejectWithValue(validateApiError(e))
  }
})
export const getScheduledReportList = createAsyncThunk<ScheduledReport[], undefined, ApiRejectResponse>(
  'REPORTS/scheduledReportList',
  async (payload, { rejectWithValue }) => {
    try {
      const resp = await restClient(apiRoutes.GET_SCHEDULED_REPORTS_LIST)
      return objectToCamel(resp.data.reports) as any
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const createScheduleReport = createAsyncThunk<ReportsResponse, ScheduleReportPayload, ApiRejectResponse>(
  'REPORTS/createScheduleReport',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.CREATE_SCHEDULE_REPORT, {
        data: payload,
        headers: {
          isToolkitCompatible: true
        }
      })
      dispatch(
        setSuccessSnackBar({
          message: 'schedule_report_success'
        })
      )
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const updateScheduledReport = createAsyncThunk<undefined, UpdateScheduledReportPayload, ApiRejectResponse>(
  'REPORTS/updateScheduledReport',
  async (payload, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.UPDATE_SCHEDULED_REPORT, { data: payload })
      dispatch(
        setSuccessSnackBar({
          message: 'schedule_report_updated_success'
        })
      )
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

export const deleteScheduledReport = createAsyncThunk<boolean, DeleteScheduledReportPayload, ApiRejectResponse>(
  'REPORTS/deleteScheduledReport',
  async ({ report_id }, { rejectWithValue, dispatch }) => {
    try {
      const resp = await restClient(apiRoutes.DELETE_SCHEDULED_REPORT, {
        urlParams: { report_id }
      })
      dispatch(
        setSuccessSnackBar({
          message: 'schedule_report_deleted_success'
        })
      )
      return resp.data
    } catch (e) {
      return rejectWithValue(validateApiError(e))
    }
  }
)

// eslint-disable-next-line no-promise-executor-return
const wait = (n: number) => new Promise(resolve => setTimeout(resolve, n))
