import OVERVIEW_TAB_EN from 'config/i18n/tabs/overview_en'
import INBOUND_SETTINGS_TAB_EN from 'config/i18n/tabs/inbound_settings_en'
import OUTBOUND_SETTINGS_TAB_EN from 'config/i18n/tabs/outbound_settings_en'
import USERS_TAB_EN from 'config/i18n/tabs/users_en'
import SUPPORT_EN from 'config/i18n/support_en'
import DOMAINS_TAB_EN from 'config/i18n/tabs/domains_en'

/* eslint-disable quotes */
// eslint-disable-next-line import/no-anonymous-default-export

export default {
  ess: {
    overview: OVERVIEW_TAB_EN,
    inbound_settings: INBOUND_SETTINGS_TAB_EN,
    outbound_settings: OUTBOUND_SETTINGS_TAB_EN,
    users: USERS_TAB_EN,
    domains: DOMAINS_TAB_EN,

    account_switcher: {
      switching_account: '「{account}」アカウントに切り替えています...',
      cancel: '[キャンセル]'
    },
    ui_switcher: {
      new_interface: '新しいインターフェース'
    },
    nivo: {
      choropleth_tooltip_attacks: '{カウント、複数形、=0 {攻撃} 1 {攻撃} その他 {攻撃}}'
    },
    support_page: SUPPORT_EN,
    layout: {
      yes: 'はい',
      no: 'いいえ',
      cancel: '[キャンセル]',
      save_changes: '変更を保存',
      search: '検索',
      unsaved_dialog: {
        title: '未保存の変更',
        text: 'このページには保存されていない変更があります。続けますか?'
      },
      global_dialog: {
        not_supported_role_type: {
          title: 'クラシックインターフェースへのリダイレクト',
          text: '現在、ドメイン管理者権限またはヘルプデスク権限を持つユーザーは、クラシックインターフェイスでのみサポートされています。',
          button: 'リダイレクト'
        },
        invalid_access: {
          title: 'アクセスが無効です',
          text: 'セキュリティ強化機能 (SSO) は有効になっていません。バラクーダネットワークスのテクニカルサポートにお問い合わせください。',
          button: 'オーケー'
        }
      }
    },
    app: {
      error: {
        default: 'おっと!何かがおかしくなった',
        failed_to_load_content: 'コンテンツを読み込めませんでした',
        add_domain_error_duplicate: 'このドメイン名は既に追加されています。',
        add_domain_error_quarantine_notifier: '隔離通知設定を作成できませんでした',
        add_domain_error_blacklisted_domain_name: 'ブラックリストに登録されたドメイン名',
        delete_domain_failure_not_found: '削除するドメインが見つかりませんでした',
        delete_domain_failure_quarantine_notifier:
          'ドメインは削除されましたが、関連する検疫通知設定を削除できませんでした',
        manage_domain_failure_forbidden: '選択したドメインを管理するための十分な権限がありません',
        edit_domain_failure_forbidden: '選択したドメインを編集するための十分な権限がありません',
        get_domain_failure: 'ドメインデータの取得中にエラーが発生しました'
      }
    },
    loading: '読み込み中...',
    expiration_banner: {
      free_trial_expires: '無料トライアルの期限が切れました'
    },
    global_message_banner: {
      impersonated_user_text: '<b>{user}</b> としてログインしました。',
      impersonated_user_button: 'ログアウト',
      pd_domain_id_text: '現在、<b>{domain}</b> の設定を管理しています。',
      pd_domain_id_text_helpdesk: '<b>{domain}</b> のヘルプデスクの役割を果たしています',
      pd_domain_id_button: 'ドメイン管理に戻る。',
      wizard_text: 'Email Gateway Defenseのセットアップがまだ完了していないようです。',
      wizard_link: 'セットアップ',
      wizard_complete_text: 'セットアップ完了。メールの到着を待つ...。'
    },
    banners: {
      impersonated_account_admin:
        '管理者は {accountId} としてログインしました。<link>このアカウントからログアウトします。</link>'
    },
    advanced_search: {
      keywords: 'キーワード',
      from: 'から',
      envelope_from: 'エンベロープから',
      to: 'に',
      envelope_to: 'エンベロープへ',
      subject: '件名',
      attachment_name: '添付ファイル名',
      start_date: '開始日',
      end_date: '終了日'
    },
    save_search: {
      title: '検索を保存',
      must_enter_name: '名前を入力する必要があります',
      valid_name: ' ',
      name_your_search: '保存した検索に名前を付ける...'
    },
    open_search: {
      title: 'オープンサーチ',
      subtitle: '[名前]',
      find_your_search: '保存した検索条件を検索...'
    },
    login: {
      title: 'サインイン',
      signin_message: 'メールゲートウェイディフェンスのメッセージと設定にアクセスするには、サインインしてください',
      signin_message_email: '<b>{email}</b> のパスワードを入力してください',
      invalid_email: 'Eメールアドレスまたはパスワードが無効です',
      password: '[パスワード]',
      email: 'Eメールアドレス',
      reset_password_message: 'パスワードは正常にリセットされました',
      valid_email_message: '有効なEメールアドレスを入力してください',
      signin: 'サインイン',
      next: '[次へ]',
      different_account_message: '別のアカウントにサインインする',
      forgot_password: 'パスワードをお忘れですか？',
      admin_signin: '管理者サインイン',
      signing_in: 'しばらくお待ちください...',
      expired_message: 'ログインリンクの有効期限が切れています',
      is_not_loading: 'クラシックインターフェースへのリダイレクト',
      invalid_link:
        '技術的な問題により、メールゲートウェイディフェンスに新しいユーザーインターフェイスを読み込めません。メールゲートウェイディフェンスのクラシックユーザーインターフェイスにリダイレクトします。',
      redirect: 'リダイレクト',
      login_failed: 'ログインできませんでした',
      new_link_sent: 'メールに新しいリンクが送信されました',
      signin_shared_mailbox: '共有メールボックスまたは配布リストにサインインしていますか?',
      signin_temporary_passcode: '<a>ログイン用の仮パスコードをメールでお送りします</a>。',
      temporary_passcode_title: '仮パスコードを入力',
      temporary_passcode_text: '{email} に一時的なパスコードを送信しました。パスコードは 15 分間有効です。',
      havent_received_email: 'メールが届いていませんか？',
      resend_temporary_passcode:
        '数分かかる場合があります。しばらく経っている場合は、<a>仮のパスコードを再送信できます</a>。',
      already_have_passcode: 'すでに仮パスコードをお持ちですか？',
      temporary_passcode: 'テンポラリー・パスコード',
      invalid_temporary_login:
        'パスコードが無効か、有効期限が切れています。Eメールを確認して、もう一度試してください。',
      temporary_passcode_sent: '仮のパスコードがEメールに送信されました。これには数分かかる場合があります。',
      temporary_passcode_limit:
        '{ExpirationTime} 分のセッションあたりの {rateLimit} 一時パスコードの制限に達しました。メールをチェックして、最新の一時パスコードを確認してください。',
      temporary_feature_not_enabled:
        '{domainName} ではテンポラリー・パスコード機能が有効になっていません。システム管理者に確認してください。',
      internal_server_error: '内部サーバーエラー',
      message: {
        title: 'メールを確認',
        sent_message: 'パスワードをリセットするためのリンクが記載されたメールが {email} に送信されました。',
        expiry_message: 'リンクの有効期限は1時間。',
        resend: 'リンクを再送信'
      },
      azure_temporary_passcode_title: 'ログインオプション',
      continue: '続行',
      welcome: 'ログイン',
      welcome_message: 'Email Gateway Defenseにサインインして、メッセージや設定にアクセスする。',
      go_to_signin: 'サインインに戻る',
      option_divider_text: 'または',
      resend_link: 'リンクを再送する',
      sign_in_with_microsoft: 'マイクロソフトを続ける',
      email_a_temporary_passcode: '一時的なパスコードをメールで送る',
      password_for: '{email}のパスワードを入力してください。',
      signin_option_for: '{email}メール}のサインインオプションを選択する。',
      didnt_receive_email: 'メールが届きませんか？',
      resend_code: 'コードの再送'
    },
    bcc_login: {
      error_page: {
        401: {
          title: '管理者を認証できません',
          subtitle: '有効な認証情報でもう一度試してください。'
        },
        default: {
          title: 'サーバーエラー',
          subtitle: 'サポートにお問い合わせください。'
        }
      }
    },
    reset_password: {
      title: 'パスワードをリセット',
      reset_password_message: '{email} の新しいパスワードを入力してください',
      password1: '新しいパスワード',
      password2: '新しいパスワードを確認',
      password_matches: 'パスワードが一致しました',
      password_not_match: 'パスワードが一致しません',
      new_link_sent: 'メールに新しいリンクが送信されました',
      reset: 'リセット',
      expired_link: {
        title: '期限切れリンク',
        message: '{email} のパスワードをリセットするためのリンクの有効期限が切れました。',
        sign_in: 'サインインに戻る',
        resend_link: 'リンクを再送信'
      },
      check_email: {
        title: 'メールを確認',
        message: 'パスワードをリセットするためのリンクが記載されたメールが {email} に送信されました。',
        link_will_expire: 'リンクの有効期限は1時間。'
      },
      successful_reset: {
        title: 'パスワードは正常にリセットされました。',
        sign_in: 'サインイン'
      },
      error: {
        send_login_info_failed: 'ログイン情報を送信できませんでした',
        resend_link_failed: 'リンクを再送信できませんでした'
      },
      didnt_receive_email: 'メールが届きませんか？',
      resend_link: 'リンクを再送する',
      password_reset_failed: 'パスワードのリセットに失敗しました'
    },
    button: {
      cancel: '[キャンセル]',
      save: '変更を保存'
    },
    dialog: {
      title: 'ダイアログ',
      confirm_title: '確認',
      info_title: '[情報]',
      error_title: '[エラー]',
      cancel: '[キャンセル]',
      ok: 'OK',
      yes: 'はい',
      no: 'いいえ',
      submit: '送信',
      save: '[保存]',
      open: '[開く]',
      search: '検索',
      okay: 'はい',
      unsaved_changes: 'このページには保存されていない変更があります。続けますか?',
      antivirus_off: 'アンチウイルスをオフにすると ATP が無効になります',
      domain_settings_conflict: 'この変更は {numberOfDomains} ドメインと競合しています。',
      domain_settings_conflict_description:
        'ドメインレベルの設定は、アカウントレベルよりも優先されます。これらの変更を保存しても、そのドメインには反映されません。',
      confirm_removal: '削除を確認しますか?'
    },
    reports: {
      title: 'レポート',
      search: '検索',
      domain: 'ドメイン',
      email_flow: 'E メールフロー',
      inbound: 'インバウンド',
      outbound: 'アウトバウンド',
      display: 'ディスプレイ',
      all_domains: 'すべてのドメイン',
      top: 'トップ {カウント}',
      display_all: '[すべて]',
      unable_to_load: '読み込めません',
      no_data_found: 'データが見つかりません',
      invalid_date: '日付範囲が無効です',
      shortcut_items: {
        last_day: '最終日',
        last_7_days: '過去 7 日間',
        last_30_days: '過去 30 日間',
        reset: 'リセット'
      },
      intervals: {
        hourly: '1 時間ごとの間隔',
        daily: '毎日の間隔',
        weekly: '週間間隔'
      },
      conditions_equals: '等しい',
      conditions_not_equals: '等しくない',
      report_types: {
        barracuda: 'バラクーダレポート',
        custom: 'マイレポート'
      },
      predefined_report_types: {
        inbound_traffic_summary: 'インバウンドトラフィックの概要',
        outbound_traffic_summary: 'アウトバウンドトラフィックの概要',
        inbound_blocked_emails_breakdown: '受信ブロックされたメールの内訳',
        top_inbound_email_senders: '受信メールの上位送信者',
        top_inbound_email_recipients: '受信メールの上位受信者',
        top_inbound_blocked_senders_breakdown: '受信拒否リストの上位受信者の内訳',
        top_inbound_blocked_recipients_breakdown: '受信拒否対象の上位受信者の内訳',
        top_outbound_email_senders: 'アウトバウンドメールの上位送信者',
        top_outbound_blocked_senders: 'ブロックされたアウトバウンドの上位送信者'
      },
      tabs: {
        reports: 'レポートリスト',
        report_list: 'レポート一覧',
        scheduled_reports: '予定レポート'
      },
      title_bar: {
        download_button: 'としてエクスポート',
        save_as: '名前を付けて保存',
        download_csv: 'CSV としてダウンロード',
        download_pdf: 'PDF としてダウンロード',
        schedule_report: 'スケジュールレポート',
        close: '閉じる',
        save: '[保存]',
        save_report_dialog: {
          title: 'レポートを保存',
          subtitle:
            '名前を入力してこのレポートと現在選択されているパラメータを保存します。今後、このレポートには [マイレポート] からアクセスできます。',
          placeholder_text: 'レポート名',
          date_warning:
            '{開始日} から {終了日} までに保存していたデータは、{deleteDate} を過ぎると完全に削除されます。これは、弊社の {RetentionPolicyDayRange} 日データ保持ポリシーによるものです。このデータに引き続きアクセスするには、{ExpirationDate} より前に CSV または PDF としてダウンロードしてください。',
          expired_date_range:
            '選択した日付範囲にアクセスできません。このレポートを保存するには、有効な時間範囲を選択してください。'
        }
      },
      labels: {
        report_date: '日付',
        email: '電子メール',
        sender_email: '送信者電子メール',
        recipient_email: '受信者電子メール',
        total_deferred: '繰延合計',
        total_blocked: 'ブロックされた合計数',
        total_quarantined: '隔離総数',
        total_encrypted: '暗号化された合計数',
        total_allowed: '合計許容値',
        blocked_by_rate_control: 'レートコントロールによるブロック',
        blocked_by_policy: 'ポリシーによりブロックされました',
        blocked_by_spam: '迷惑メールによるブロック',
        blocked_for_virus: 'ウイルスブロック済み',
        blocked_by_atp: 'ATP によってブロックされました',
        blocked_other: 'ブロックされたその他'
      },
      schedule_report: {
        title: 'スケジュールレポート',
        report_name: 'レポート名',
        time_range: '時間範囲',
        format: 'フォーマット',
        last_month: '過去 30 日間',
        last_week: '過去 7 日間',
        last_day: '最終日',
        csv: 'データ CSV',
        frequency: '周波数',
        daily: 'デイリー',
        weekly: '毎週',
        monthly: '毎月',
        recipients: '受信者',
        cancel: '[キャンセル]',
        schedule: 'スケジュール',
        on: 'オン',
        every: 'ごと',
        first: '最初',
        second: '二番目',
        third: 'サード',
        fourth: '第四に',
        last: '最後',
        1: 'モ',
        2: '火曜日',
        3: '私たち',
        4: 'Th',
        5: '神父',
        6: 'スパ',
        7: 'スー',
        invalid_recipient: '電子メールアドレス {email} の受信者は有効ではありません。',
        missing_recipients: '受信者を入力してください。',
        too_many_recipients: '受信者数が多すぎる。受信者数の上限は50人。',
        duplicate_recipient: '{email} は重複受信者である。'
      },
      scheduled_reports: {
        title: '定期レポート',
        new_schedule: '新しいスケジュール',
        search: '検索',
        sent: '送信済み',
        disabled: '無効',
        processing: '処理',
        csv: 'CSVファイルです',
        pdf: 'PDFファイル',
        last_month: '過去 30 日間',
        last_week: '過去 7 日間',
        last_day: '最終日',
        daily: 'デイリー',
        weekly: '毎週',
        monthly: '毎月',
        scheduled_time: '{time} に予定されているレポート',
        next_schedule: '次回の配達予定日:',
        last_schedule: '最終送信日:',
        today: '今日',
        at: 'で',
        disable_schedule: 'スケジュールを無効にする',
        enable_schedule: 'スケジュールを有効にする',
        delete_schedule: 'スケジュールを削除',
        open_report: 'レポートを開く',
        more: 'もっと'
      }
    },
    settings: {
      title: '[設定]',
      tabs: {
        quarantine_notification: '隔離通知',
        sender_policies: '送信者ポリシー',
        linked_accounts: 'リンクされたアカウント',
        change_password: 'パスワードを変更'
      },
      bulk_edit: {
        buttons: {
          save: '変更を保存',
          cancel: '[キャンセル]'
        },
        rate_control_title: '一括編集:料金管理免除',
        custom_rbls_title: '一括編集:カスタム RBL',
        ip_policies_title: '一括編集:IP アドレスポリシー',
        trusted_forwarders_title: '一括編集:信頼できるフォワーダー',
        geoip_policies_title: '一括編集:GeoIP ポリシー',
        language_policies_title: '一括編集:言語ポリシー',
        recipient_policies_title: '一括編集:受信者ポリシー',
        sender_policies_title: '一括編集:送信者ポリシー',
        dmarc_exempt_title: '一括編集:DMARC 免除',
        dkim_exempt_title: '一括編集:DKIM 免除',
        spf_exempt_title: '一括編集:IP アドレスによる SPF 免除',
        spf_domain_exempt_title: '一括編集:ドメイン別の SPF 免除',
        spf_missing_exempt_title: '一括編集:SPF 免除が見つかりません',
        ptr_exempt_title: '一括編集:PTR 免除',
        attachment_filter_title: '一括編集:添付ファイルフィルター',
        message_filter_title: '一括編集:メッセージコンテンツフィルタ',
        intent_domains_title: '一括編集:インテントドメインポリシー',
        predefined_filters_title: '一括編集:件名',
        predefined_filters_exceptions_title: '一括編集:定義済みのフィルター例外',
        dlp_encryption_title: '一括編集:DLP/暗号化',
        sender_ip_title: '一括編集:送信者 IP アドレス範囲',
        inbound_tls_domains_title: '一括編集:強制 TLS ドメイン',
        inbound_tls_domain_exemptions_title: '一括編集:TLS ドメインの免除',
        geoip_sender_exempt_title: '一括編集:メールアドレス/ドメイン別の GeoIP 適用除外',
        geoip_exempt_title: '一括編集:送信者 IP アドレスによる GeoIP 適用除外',
        machine_learning_exempt_title: '一括編集:メールアドレス/ドメイン別の機械学習適用除外',
        place_holder: 'ここにポリシーまたは過去のポリシーを書いてください',
        title: '一括編集:送信者ポリシー',
        header:
          '送信者 (メールアドレス、ドメイン、またはユーザ)、ポリシー (ブロック、免除、隔離)、コメント (オプション)',
        custom_rbls_header: 'ホスト名、コメント (オプション)',
        control_rate_header: 'IP アドレス、ネットマスク、コメント (オプション)',
        ip_policy_header: 'IP アドレス、ネットマスク、ポリシー (ブロック、免除)、コメント (オプション)',
        trusted_forwarders_header: 'IP アドレス、ネットマスク、コメント (オプション)',
        geoip_policies_header: '国 (ISO アルファ-3)、ポリシー (ブロック、隔離)、コメント (オプション)',
        language_policies_header: '言語、ポリシー (ブロック、隔離)、コメント (オプション)',
        recipient_policies_header: 'メールアドレス、ポリシー (スキャン、免除)、コメント (オプション)',
        sender_policies_header_cpl: 'メールアドレス、ポリシー (ブロック、免除)、コメント (オプション)',
        sender_policies_header: 'メールアドレス、ポリシー (ブロック、免除、隔離)、コメント (オプション)',
        geoip_exempt_header: 'IP アドレス、ネットマスク、コメント (オプション)',
        geoip_sender_exempt_header: 'メール/ドメイン、コメント (オプション)',
        dmarc_exempt_header: 'ドメイン、コメント (オプション)',
        dkim_exempt_header: 'ドメイン、コメント (オプション)',
        spf_exempt_header: 'IP アドレス、ネットマスク、コメント (オプション)',
        spf_domain_exempt_header: 'ドメイン、コメント (オプション)',
        spf_missing_exempt_header: 'IP アドレス、ネットマスク、コメント (オプション)',
        ptr_exempt_header: 'IP アドレス、ネットマスク、コメント (オプション)',
        sender_ip_header: 'ログとポリシードメイン、IP アドレス、ネットマスク、コメント (オプション)',
        machine_learning_exempt_header: 'メール/ドメイン、コメント (オプション)',
        attachment_filter_header:
          'フィルタータイプ (ファイル名/MIME)、パターン (ワイルドカード表現または MIME タイプ)、アーカイブをチェック (1/0)、アクション (ブロック/無視/隔離)、コメント (オプション)',
        attachment_filter_header_cpl:
          'フィルタータイプ (ファイル名/MIME)、パターン (ワイルドカード式または MIME タイプ)、チェックアーカイブ (1/0)、アクション (ブロック/無視)、コメント (オプション)',
        message_filter_header:
          'パターン (正規表現)、アクション (ブロック/許可/隔離)、マッチフィルター (件名、ヘッダー、本文、添付ファイル、送信者、受信者のカンマ区切りリスト)',
        outbound_message_filter_header:
          'パターン (正規表現)、アクション (ブロック/許可/隔離/暗号化/not_encrypt)、マッチフィルター (件名、ヘッダー、本文、添付ファイル、送信者、受信者のカンマ区切りリスト)',
        message_filter_header_cpl:
          'パターン (正規表現)、アクション (ブロック/許可)、マッチフィルター (件名、ヘッダー、本文、添付ファイル、送信者、受信者のカンマ区切りリスト)',
        intent_domains_header: 'ドメイン、ポリシー (無視/ブロック/隔離)、コメント (オプション)',
        intent_domains_header_cpl: 'ドメイン、ポリシー (無視/ブロック)、コメント (オプション)',
        predefined_filters_header_cpl:
          'パターン (クレジットカード/ssn/privacy/hipaa)、アクション (ブロック)、マッチフィルター (件名、ヘッダー、本文、添付ファイルのカンマ区切りリスト)',
        predefined_filters_header:
          'パターン (クレジットカード/ssn/privacy/hipaa)、アクション (ブロック/隔離/暗号化)、マッチフィルター (件名、ヘッダー、本文、添付ファイルのカンマ区切りリスト)',
        predefined_filters_exception_header: 'タイプ (電話番号/住所)、パターン',
        dlp_encryption_header: 'ドメイン名、コメント (オプション)',
        inbound_tls_domains_header: 'ドメイン名、コメント (オプション)',
        inbound_tls_domain_exemptions_header: 'ドメイン名、コメント (オプション)',
        sub_title: '一括編集では、設定のリストに対して複数の変更 (追加、削除、修正) を 1 ステップで実行できます。',
        content_header: 'CSV データの編集',
        content_sub_header:
          '多くの列は特定の形式のデータしか受け付けられません。たとえば、電子メールアドレスに関連する列には、電子メールアドレスの形式で表示されるデータが含まれている必要があります。列の中には、オプションのデータやデフォルト値が関連付けられているものがあり、空欄であってもかまいません。',
        warning:
          '注:最初の行は解析されないため、有効なデータが含まれていないことを確認してください。必要に応じて 1 行目に列タイトルを付けてください。',
        error_missing_policy: '行 %d のエラー (ポリシーが見つかりません)',
        error_invalid_name: '行 %d のエラー (名前が無効です)',
        error_invalid_policy: '行 %d のエラー (ポリシーが無効です)',
        error_missing_zone: '行 {row} のエラー (ゾーンが見つかりません)',
        error_invalid_zone: '行 {row} のエラー (ゾーンが無効です)',
        error_missing_address: '行 {row} のエラー (アドレスが見つかりません)',
        error_invalid_address: '行 {row} のエラー (アドレスが無効です)',
        error_missing_netmask: '行 {row} のエラー (ネットマスクが見つかりません)',
        error_invalid_netmask: '行 {row} のエラー (ネットマスクが無効です)',
        error_missing_ip_policy: '行 {row} でエラーが発生しました (ポリシーが指定されていません)',
        error_invalid_ip_policy: '行 {row} のエラー (ポリシーが無効です)',
        error_missing_country: '行 {row} にエラーがあります (国が入力されていません)',
        error_invalid_country: '行 {row} のエラー (国コードが無効です)',
        error_missing_email: '行 {row} のエラー (電子メールアドレスが見つかりません)',
        error_invalid_email: '行 {row} のエラー (Eメールアドレスが無効です: {item})',
        error_missing_domain: '行 %d のエラー (ドメインが見つかりません)',
        error_invalid_domain: '行 %d のエラー (ドメインが無効です)',
        error_missing_domain_name: '行 %d でエラーが発生しました (ドメイン名を指定してください)',
        error_invalid_ip_address: '行 %d でエラーが発生しました (有効な IP アドレスを入力してください)',
        error_on_row: '行 {row} でエラーが発生しました',
        error_missing_filter_type: 'フィルタータイプが見つかりません',
        error_invalid_filter_type: 'フィルタータイプが無効です',
        error_missing_filter_pattern: 'フィルターパターンが見つかりません',
        error_missing_predefined_filter_pattern: '定義済みのパターンが見つかりません',
        error_invalid_filter_pattern: 'フィルターパターンが無効です',
        error_missing_archive_check: 'アーカイブチェックが見つかりません',
        error_invalid_archive_check: 'アーカイブチェックが無効です',
        error_missing_filter_action: 'フィルターアクションが見つかりません',
        error_invalid_filter_action: 'フィルターアクションが無効です',
        error_missing_match_filter: '少なくとも 1 つのマッチフィルターを指定する必要があります',
        error_invalid_match_filter: '指定されたマッチフィルタは無効です',
        error_missing_type: 'タイプが見つかりません',
        error_invalid_type: 'タイプが無効です',
        error_invalid_phone_number: '電話番号が無効です',
        error_subnet_too_large:
          '行 {row} でエラーが発生しました (指定されたサブネット範囲が大きすぎます。複数の /24 サブネットに分割してください。)',
        error_new_entry_single_ip:
          '行 {row} でエラーが発生しました (新しいエントリは単一の IP 番号のみである可能性があります)。',
        error_is_not_verified_domain: '行 {row} のエラー (ドメイン名は検証済みのドメインでなければなりません)',
        error_already_specified:
          '行 {row} のエラー (指定されたサブネットは、別のアカウントのサブネットと競合しています。)',
        error_potentially_shared_infrastructure:
          '行 {row} でエラーが発生しました (選択した IP アドレス/範囲は、共有インフラストラクチャの可能性があるとマークされている範囲の一部です。これを特定のドメインに割り当てることはできません。)',
        error_no_reverse_dns: '行 {row} のエラー (指定した IP アドレスにはリバース DNS がありません)',
        error_conflict_subnet: '行 {row} のエラー (指定されたサブネットはサブネット:{ip}/{netmask} と競合しています)',
        error_duplicate_value: '行 {row} でエラーが発生しました (値が重複しています:{item})',
        error_invalid_language: '言語コードが無効です',
        error_missing_language: '言語が見つかりません',
        error_missing_machine_learning_exempt: 'Eメールアドレス/ドメインが見つかりません',
        error_invalid_email_domain: 'Eメールまたはドメインが無効です'
      },
      ip_address_table: {
        ip_address: 'IP アドレス',
        netmask: 'ネットマスク',
        policy: 'ポリシー',
        comment: '[コメント]',
        actions: '[アクション]',
        add: '追加',
        remove: '[削除]',
        confirm_removal: '削除を確認しますか?',
        block: '[ブロック]',
        exempt: '免除',
        error: {
          empty_ip_address: '有効な IP アドレスを入力してください。',
          invalid_ip_address: 'IP アドレスが無効です。',
          empty_netmask: '有効なネットマスクを入力してください。',
          invalid_netmask: 'ネットマスクが無効です。',
          duplicate_value: '値が重複しています:{item}'
        }
      },
      domain_table: {
        domains: 'ドメイン',
        email_domain: 'メール/ドメイン',
        comment: '[コメント]',
        actions: '[アクション]',
        add: '追加',
        remove: '[削除]',
        confirm_removal: '削除を確認しますか?',
        error: {
          empty_domain: 'ドメイン名を指定してください。',
          invalid_domain: '入力されたドメイン名は無効です。',
          duplicate_value: '値が重複しています:{item}',
          invalid_email_domain: '入力されたドメイン/Eメールは有効ではありません。'
        }
      },
      change_password: {},
      quarantine_notification: {
        reset_dialog: {
          title: '設定をリセット',
          message: '設定をリセットしてよろしいですか?'
        },
        buttons: {
          save: '[保存]',
          reset: 'リセット',
          default_interval: {
            never: '決して',
            scheduled: 'スケジュール済み'
          }
        },
        title: '隔離通知',
        disabled_warning: 'この設定への変更は、ドメイン管理者によって無効にされています。',
        default_interval: 'ユーザー隔離通知のデフォルト間隔',
        schedule_notification: '通知間隔をスケジュールする',
        schedule_notification_sub_title:
          '通知は、選択した時間ブロック内の任意の時点で送信されます。クリックしてドラッグして選択します。Shift キーを押したままドラッグすると、選択が解除されます。',
        allow_override: 'この設定への変更は、ドメイン管理者によって無効にされています'
      },
      sender_policies: {
        invalid_domain: '有効なドメイン名を入力してください',
        invalid_domain_title: 'ドメインが無効です',
        confirm_removal: 'ポリシーを削除してよろしいですか?',
        confirm_removal_title: '削除を確認',
        block: '[ブロック]',
        exempt: '免除',
        quarantine: '検疫',
        warningHoverMessage: 'あなたの組織はこの送信者をブロックしました',
        buttons: {
          bulk_edit: '一括編集',
          add: '追加',
          remove: '[削除]'
        },
        tableHeadings: {
          sender: '送信者',
          policy: 'ポリシー',
          comment: '[コメント]',
          modified: '修正済み',
          actions: '[アクション]'
        },
        title: '送信者ポリシー',
        sub_title: '特定のメールアドレスまたはドメインからのメッセージをブロックするか、除外するかを指定します。',
        sender_policy_whitelist_notification_message:
          '組織では、メールアドレスとドメインをブロックおよび許可する機能が制限されています。何らかの矛盾がある送信者ポリシーには、その横に警告記号が表示されます。'
      },
      linked_accounts: {
        title: 'リンクされたアカウント',
        sub_title: '同じドメインにあるメールアドレスを追加して、隔離されたメールをこのアカウントに転送できます。',
        verification_message:
          'このアカウントの確認方法が記載されたメールが {email} に送信されました。このアカウントは確認されるまでリンクされません。',
        confirm_unlink: 'アカウント {email} のリンクを解除してよろしいですか？',
        account: 'アカウント (メールアドレス)',
        add: '追加',
        unlink: '[リンク解除]',
        actions: '[アクション]',
        unlink_title: 'リンク解除を確認',
        invalid_params: 'パラメータが無効です',
        email_sent: 'これで、メールアカウント {userId} がアカウント {email} にリンクされました。',
        verifying: '連結アカウントを確認中...',
        unable_to_link: 'これらのアカウントをリンクできません'
      }
    },
    message_log: {
      title: 'メッセージログ',
      outbound_quarantine_title: 'アウトバウンド検疫',
      more_filters: 'その他のフィルター',
      search_bar: {
        search: '検索',
        find_hint: 'メッセージ、受信者、送信者などを検索する...'
      },
      columns: {
        status: 'ステータス',
        from: 'から',
        to: 'に',
        subject: '件名',
        date: '日付',
        size: 'サイズ',
        reason: '理由',
        score: 'スコア',
        details: '詳細'
      },
      toolbar: {
        refresh: 'リフレッシュ',
        deliver: '配信',
        export: 'テーブルを CSV にエクスポート',
        categorize: '分類する',
        delete: 'メッセージを削除',
        reject: 'メッセージを拒否',
        corp_email: '企業電子メール',
        trans_email: 'トランザクション電子メール',
        market_materials: 'マーケティング資料とニュースレター',
        mailing_lists: 'メーリングリスト',
        social_media: 'ソーシャル・メディア',
        other: 'その他',
        message_queue: 'メッセージキュー',
        deliver_dialog: {
          title: 'メッセージを配信',
          text: 'このメッセージは受信者に配信されます。送信者またはドメインを許可リストに追加して、今後のメッセージを配信できます。オプションで、バラクーダチームが脅威をより正確に特定できるように、メールを報告してください。 ',
          learn_more_link: 'さらに詳しく',
          deliver_only: 'メッセージのみを配信する',
          allow_list_sender: '送信者を配信して許可リストに追加する',
          allow_list_domain: 'ドメインを配信して許可リストに追加する',
          deliver_report: '配信と報告',
          deliver: '配信'
        },
        categorize_other_box_title: 'カテゴリを指定してください',
        limit_special_chars: '使用できる文字は、Aa-Zz、0-9、-_、およびスペースです',
        character_limit: '30 文字のみ入力できます',
        new_email: '新しいメッセージ'
      },
      from_column_menu: {
        show_name: '名前を表示',
        show_email: 'Eメールアドレスを表示',
        show_both: '両方を表示'
      },
      message_detail_toolbar: {
        downloading: 'ダウンロード中',
        incorrectly_allowed: '誤って配信されたことを報告する',
        incorrectly_blocked: '誤ってブロックされたと報告',
        menu: {
          download: 'メッセージをダウンロード',
          allow_deliver: '送信者を許可してメッセージを配信',
          add_email: '送信者メールをブロックリストに追加する',
          add_domain: '送信者ドメインをブロックリストに追加',
          delete: '[削除]',
          delete_message: 'メッセージを削除',
          reject_message: 'メッセージを拒否',
          show_history: '履歴を表示',
          hide_history: '履歴を非表示にする',
          reply: '返信',
          reply_all: '全員に返信',
          forward: 'フォワード',
          actions: 'その他のアクション',
          view_atp_reports: 'ATP レポートを表示する'
        }
      },
      header_detail: {
        long_reason: '%s が原因でメッセージは %s でした',
        short_reason: 'メッセージは %s でした',
        remediated_by_sentinel: '許可 のなりすまし防止機能で対処 %s',
        remediated_by_forensics: '許可 インシデントレスポンスで修復 %s',
        from: 'から:',
        to: 'に:',
        subject: '件名:',
        date: '日付:',
        attachments: '添付ファイル:',
        mid: 'メッセージ ID:',
        ip: 'ヒント:',
        env_from: '封筒の差出元:',
        recipients: '受信者:'
      },
      recipients_info: {
        recipients: '受信者',
        action: 'アクション',
        reason: '理由',
        delivery_status: '配送状況'
      },
      message_preview: {
        message_too_large: 'メッセージが大きすぎます。ソースを表示するには、このメッセージをダウンロードしてください。',
        message_has_virus: 'ウイルスが検出されました。このメッセージは表示できません。',
        show_images_banner: 'このメッセージ内の画像は自動的には表示されません。',
        show_images: '画像を表示',
        cant_view_blocked: '管理者は、ブロックされたメッセージの表示と配信を無効にしています。',
        cant_view_quarantined: '管理者は、隔離されたメッセージの表示と配信を無効にしています。',
        no_content: 'このメッセージにはコンテンツがないか、コンテンツがまだありません。',
        atd_detected: 'このメッセージには、高度な脅威が検出された添付ファイルが 1 つ以上含まれています。',
        admin_disabled:
          'このアカウントはメッセージビューを無効にしました。アカウント管理者に連絡して有効にしてください。',
        encrypted: 'メッセージコンテンツは暗号化されます。'
      },
      redelivery_queue: {
        redelivering: '再配信中...',
        buttons: {
          retry: 'リトライ',
          close: '閉じる'
        },
        title: 'メッセージキュー',
        sub_title:
          'メッセージキューには、メッセージログから配信対象として指定されたメッセージが表示されます。メッセージは 12 時間後に自動的にキューから削除されます。'
      },
      email_composer: {
        reply: 'Re',
        forward: 'Fw',
        from: 'から',
        to: 'に',
        cc: 'Cc',
        attachments: '添付ファイル',
        new_email_title: '新しいメッセージ',
        subject: '件名',
        buttons: {
          cancel: '[キャンセル]',
          send: '送信'
        }
      },
      reporting_action_modal: {
        report_message: '報告メッセージ',
        incorrectly_delivered: 'レポートは誤って配信されました',
        incorrectly_blocked: '誤ってブロックされたと報告',
        incorrectly_delivered_content:
          'このメールは、バラクーダチームによるさらなる確認のため報告されました。今後このようなメッセージがブロックされるように、さらに詳しい情報を提供してください。',
        incorrectly_blocked_content:
          'このメールは、バラクーダチームによるさらなる確認のため報告されました。このようなメッセージが今後確実に届くように、さらに詳しい情報を提供してください。',
        buttons: {
          close: '閉じる',
          why_should_be_blocked: 'ブロックすべき理由を教えてください',
          why_should_be_allowed: 'なぜ許可すべきか教えてください'
        }
      },
      preset_menu: {
        label: '日付プリセット',
        one_day: '過去 1 日',
        two_days: '過去 2 日間',
        one_week: '過去 1 週間',
        two_weeks: '過去 2 週間',
        one_month: '過去 1 か月',
        custom: 'カスタム'
      },
      domain_menu: {
        label: 'ドメイン'
      },
      action: {
        label: '実行されたアクション',
        any: '任意',
        allowed: '許可済み',
        blocked: 'ブロック済み',
        quarantined: '隔離されました',
        encrypted: '暗号化済み',
        deferred: '延期',
        remediated: '是正済み',
        email_continuity: 'Eメールの継続'
      },
      action_for_some: {
        allowed: '一部は許可されています',
        blocked: '一部ブロック済み',
        quarantined: '一部は隔離されました',
        encrypted: '一部は暗号化されています',
        deferred: '一部は延期'
      },
      status: {
        label: '配送状況',
        any: '任意',
        delivered: '配信済み',
        delivered_partial: '一部に配信',
        spooled: 'スプール',
        not_delivered: '未配送',
        deferred: '延期',
        rejected: '拒否'
      },
      encryption: {
        inbound_outbound_encrypted: '受信暗号化 + 送信暗号化',
        inbound_encrypted: '受信暗号化のみ',
        outbound_encrypted: 'アウトバウンド暗号化のみ',
        encrypted_for_some: 'インバウンド暗号化 + 一部の受信者向けのアウトバウンド暗号化'
      },
      reason: {
        label: '理由',
        any: '任意',
        spf: '送信者ポリシーフレームワーク (SPF)',
        bafi: '不正対策',
        barracuda_email_blacklist: '送信者メールアドレス',
        av: 'ウイルス対策',
        bbl: 'バラクーダの評判',
        brts: 'バラクーダリアルタイムシステム (BRTS)',
        content_url: 'コンテンツ URL',
        content_protected: 'コンテンツ保護',
        intent: '意図分析',
        image_analysis: '画像分析',
        rbl: 'リアルタイムブロックリスト',
        content_subject: '件名の内容',
        content_header: 'ヘッダーコンテンツ',
        content_body: 'ボディコンテンツ',
        predefined_filter_exemption: '定義済みフィルター免除',
        predefined_content_sender: '事前定義済の差出人住所',
        content_attachment: '添付ファイルの内容',
        content_sender: '差出人住所',
        content_recipient: '宛先/CC アドレス',
        invalid_recipient: '受信者が無効です',
        dest_invalid_recipient: '受信者が無効です',
        dkim: 'ドメインキー (DKIM)',
        dmarc: 'DMARC',
        encryption_exemption: '暗号化例外',
        mark_spam: '迷惑メール分類に送信',
        no_prt: 'PTR レコードなし',
        no_spf: 'SPF レコードなし',
        password_protected_pdf: 'パスワードで保護された PDF フィルタリング',
        patd_scan_pending: '保留中のスキャン',
        phishline: 'セキュリティ意識向上トレーニング',
        predefined_content_subject: '定義済みのサブジェクトコンテンツ',
        predefined_content_header: '定義済みのヘッダーコンテンツ',
        predefined_content_body: '定義済みの本文コンテンツ',
        predefined_content_attachment: '定義済みの添付ファイルの内容',
        predefined_content_recipient: '定義済みの To/CC アドレス',
        rate_control: 'レートコントロール',
        sender: '送信者ポリシー',
        ip: 'IP アドレスポリシー',
        tls_required: 'TLS が必要です',
        inbound_tls_required: 'インバウンド TLS が必要です',
        attachment_filter: '添付ファイルフィルター',
        message_loop: 'メールループの可能性',
        message_size: 'メッセージが大きすぎます',
        bulk_email: '一括メール',
        office_macros: 'オフィスマクロ',
        predefined_filter_exception: '定義済みフィルター例外',
        emailcat: 'メール分類',
        system_sender: 'システム送信者ポリシー',
        recipient: '受信者',
        recipient_limit: '受信者リスト',
        remediated_by_sentinel: 'なりすまし防止による是正',
        remediated_by_forensics: 'インシデントレスポンスによる是正',
        atd_exemption: 'ATP 免除',
        atd_full: '高度な脅威対策',
        atd: '高度な脅威対策',
        atd_subscribed: '高度な脅威検知',
        atd_quarantine: 'ATP が隔離されました',
        batd_scan_pending: '保留中のスキャン',
        service_error_batd: 'ATP スキャンが不確定',
        ui_delivered: 'UI が配信されました',
        sender_disconnect: 'メッセージ配信が中断されました',
        sender_quit: 'メッセージが不完全です',
        sender_rset: 'メッセージが不完全です',
        service_error_av: 'AV サービスは利用できません',
        service_error_cs: 'クラウドスキャンサービスは利用できません',
        sender_spoof: '送信者なりすまし対策',
        score: 'スコア',
        suspicious: '疑わしい',
        suspended: 'アカウントが停止されました',
        content_protected_msdoc: 'オフィス保護',
        taxonomy_blackmail: '強要',
        taxonomy_spam: 'スパム',
        taxonomy_becspoofing: 'なりすまし',
        taxonomy_conversationhijacking: '会話ハイジャック',
        taxonomy_phishing: 'フィッシング',
        malformed: '形式が間違っています',
        language: '言語ポリシー',
        geoip: 'GeoIP ポリシー',
        'system-sender': 'システム送信者ポリシー',
        no_ptr: 'PTR レコードなし',
        ndr: 'アウトバウンドNDRポリシー'
      },
      export: {
        message_id: 'メッセージ ID',
        from: 'から',
        to: 'に',
        subject: '件名',
        date: '日付',
        size: 'サイズ',
        action: 'アクション',
        reason: '理由',
        score: 'スコア'
      },
      error: {
        no_email_domain: 'メールドメインがないため、メッセージログは使用できません'
      },
      no_records_available: '使用可能なレコードはありません',
      message_direction_menu: {
        inbound: 'インバウンド',
        outbound: 'アウトバウンド',
        label: '方向'
      },
      find_similar_messages: '類似メッセージを検索する',
      pager_count: {
        items: 'アイテム',
        of: 'の'
      }
    },
    message_detail: {
      title: 'メッセージ詳細',
      preview: 'プレビュー',
      source: '[ソース]'
    },
    splitter_orientation: {
      none: '分割なし',
      horizontal: '水平分割',
      vertical: '垂直分割'
    },
    navbar: {
      add: '追加',
      changes_saved: '変更が保存されました',
      legacy_ui: 'クラシック UI'
    },
    logoutmenu: {
      serial_number: 'シリアル番号',
      expiration: '有効期限',
      sign_out: '[サインアウト]',
      language_selector: '言語: ',
      language_title: '希望する言語を選択',
      review_license: 'ライセンスを確認する'
    },
    email_details: {
      email_from: '{date} に {送信者} から送信されたメール',
      recipients: '受信者',
      sender: '送信者',
      date: '日付',
      subject: '件名',
      headers: 'ヘッダー',
      value: '価値',
      type: 'タイプ',
      copied: 'コピーされました',
      copy_to_clipboard: 'クリップボードにコピー',
      tabs: {
        email: '電子メール',
        headers: 'ヘッダー',
        threat_details: '脅威の詳細'
      },
      threats: {
        dmarc: 'DMARC コンプライアンス',
        spf: 'SPF IP チェック',
        dkim: 'DKIM アライメント'
      }
    },
    sidemenu: {
      logout: 'ログアウト',
      profile: 'プロフィール',
      language_selector: '言語:{言語}',
      manage_profile: 'プロフィールの管理',
      review_licenses: 'レビューライセンス',
      message_log: 'メッセージログ',
      settings: '[設定]',
      support: 'サポート',
      domains: 'ドメイン',
      overview: '概要',
      inbound_settings: 'インバウンド設定',
      outbound_settings: 'アウトバウンド設定',
      atp_settings: 'ATP 設定',
      users: 'ユーザ',
      report_list: 'レポート',
      account_management: 'アカウント管理',
      settings_sender_policy: '[設定]',
      switch_to_old_ui: '古いUIに切り替える',
      new_interface: '新製品インターフェース',
      bcc_admin: 'バラクーダクラウドコントロール',
      your_apps: 'アプリ',
      email_log: 'メールログ',
      cookie_preferences: 'クッキーの設定'
    },
    change_password_page: {
      change_password: 'パスワードを変更',
      old_password: '現在のパスワード',
      new_password: '新しいパスワード',
      confirm_new_password: '新しいパスワードを確認',
      change_password_title: 'パスワード変更の確認',
      change_password_confirmation: 'パスワードを変更してよろしいですか?'
    },
    scheduler: {
      mon: '月曜',
      tue: '火曜日',
      wed: '水曜日',
      thu: '木曜日',
      fri: '金曜日',
      sat: '土曜日',
      sun: '日曜日',
      short_mon: '月曜日',
      short_tue: '火曜日',
      short_wed: '結婚した',
      short_thu: '木',
      short_fri: '金',
      short_sat: '土',
      short_sun: '太陽'
    },
    snackbar: {
      new_report_save_success: '「%s」レポートは正常に保存されました',
      new_report_save_failed: '「%s」レポートを保存できませんでした',
      load_report_failed: '「%s」レポートを読み込めませんでした',
      set_pinned_report_id_success: 'ピン留めされたレポートが正常に設定されました:「%s',
      set_pinned_report_id_failed: 'レポートをピン留めできませんでした:「%s」',
      download_attachment_failed: '添付ファイルをダウンロードできませんでした',
      download_message_failed: 'メッセージをダウンロードできませんでした',
      bad_date_range: '開始日/時刻は終了日/時刻より前である必要があります',
      post_policy_success: '送信者ポリシーが正常に追加されました',
      post_policy_failure: '送信者ポリシーを追加できませんでした',
      post_policy_duplicate_failure: '重複したエントリが存在します',
      put_bulk_edit_success: '一括編集は正常に保存されました',
      put_bulk_edit_failure: '一括編集の保存に失敗しました:%s',
      put_quarantine_notification_success: '隔離通知設定は正常に保存されました',
      put_quarantine_notification_failure: '検疫通知設定を保存できませんでした',
      delete_policy_success: '送信者ポリシーが正常に削除されました',
      delete_policy_failure: '送信者ポリシーを削除できませんでした',
      password_success: 'パスワードは正常に設定されました',
      password_failure: 'パスワードを設定できませんでした',
      reset_password_success: 'パスワードは正常にリセットされました',
      reset_password_failure: 'パスワードのリセットに失敗しました',
      get_message_source_failed: 'メッセージソースを取得できませんでした',
      local_storage_quota_exceeded: 'ブラウザのローカルストレージの容量が不足しました',
      get_redeliver_message_failure: '配信キューメッセージの取得に失敗しました',
      post_block_sender_success: '「%s」をブロックリストに追加しました',
      post_block_sender_failed: '送信者またはドメインをブロックリストに追加できませんでした',
      post_allow_sender_success: '%d 件のメッセージが許可リストに表示され、配信キューに送信されました',
      post_allow_sender_partial:
        '%d 件のメッセージが許可リストに表示され、配信キューに送信されました, %v メッセージが無効であったため、配送キューへのリストアップや送信が許可されなかった。',
      post_allow_sender_invalid:
        '%d メッセージが無効であったため、配送キューへのリストアップや送信が許可されなかった。',
      post_allow_sender_failed: '送信者またはドメインを許可リストに追加できませんでした',
      post_allow_sender_admin_forbidden: '管理者ポリシーによりアクションが禁止されています。管理者に連絡してください',
      post_redeliver_message_success: '%d 件のメッセージが配信キューに送信されました',
      post_redeliver_message_failure: 'エラーが発生しました。0 件のメッセージが配信キューに送信されました',
      post_redeliver_message_admin_forbidden:
        '管理者ポリシーによりアクションが禁止されています。管理者に連絡してください',
      post_new_email_success: 'E メールは正常に送信されました',
      post_new_email_failure: 'E メールの送信に失敗しました',
      post_recategorize_success: '%d 件のメッセージが再分類されました',
      post_recategorize_failure: 'メッセージを再分類できませんでした',
      post_add_linked_account_success: '連結アカウントは正常に追加されました',
      post_add_linked_account_failure: '連結アカウントを追加できませんでした',
      post_add_linked_account_not_same_domain_failure: '連結アカウントは %s などと同じドメインにある必要があります',
      post_add_linked_account_existed_failure:
        'このユーザーは既に存在しています。エイリアスとして追加する別のメールアドレスを選択してください。',
      post_add_linked_account_already_linked_failure: 'Eメールアドレス %s は既に %s にリンクされています',
      post_delete_linked_account_success: 'Eメールアドレスのリンクが正常に解除されました',
      post_delete_linked_account_failure: 'Eメールアドレスのリンクを解除できませんでした',
      error_password_char_limit: 'パスワードは 100 文字未満でなければなりません',
      error_new_password_not_same: '新しいパスワードを古いパスワードと同じにすることはできません',
      error_confirm_password_is_same: '[新しいパスワード] と [新しいパスワードの確認] は同じである必要があります',
      post_fp_report_success:
        '迷惑メールではないとマークされたメッセージ。メッセージを報告する際は、広告ブロッカーを無効にして、メールの有効性フィードバックフォームに記入してください。',
      post_fp_report_failure: 'メッセージを迷惑メールではないメッセージとしてマークできませんでした',
      post_fn_report_success:
        '迷惑メールとしてマークされたメッセージ。メッセージを報告する際は、広告ブロッカーを無効にして、メールの有効性フィードバックフォームに記入してください。',
      post_fn_report_failure: 'メッセージを迷惑メールとしてマークできませんでした',
      delete_message_success: '%d 件のメッセージが正常に削除されました',
      delete_message_failure: 'メッセージを削除できませんでした',
      delete_message_partial_success: '%d 件のメッセージは正常に削除され、%d 件のメッセージは削除できませんでした',
      stop_delivery_reasons: '0 件のメッセージが配信されました。「%s」が原因で配信できません',
      stop_delivery_admin: '%s メッセージの配信が管理者によって無効にされました',
      message_detail_missing: 'メッセージの詳細が見つかりません。0 件のメッセージが配信されました',
      detect_email_server_failure: 'E メールサーバーを検出できませんでした',
      verify_email_server_failure: 'E メールサーバーの検証に失敗しました',
      wizard_save_error: '保存中にエラーが発生しました。もう一度試してください',
      download_message_success: 'メッセージは正常にダウンロードされました',
      download_message_failure: 'メッセージをダウンロードできませんでした',
      download_attachment_success: '添付ファイルが正常にダウンロードされました',
      download_attachment_failure: '添付ファイルをダウンロードできませんでした',
      post_reject_messages_success: '%d 件のメッセージが拒否されました',
      post_reject_messages_partial_success: '%d 件のメッセージを拒否しました。%d 件のメッセージを拒否できませんでした',
      post_reject_messages_failed: 'メッセージを拒否できませんでした',
      success_changing_settings: '設定の変更が保存されました。',
      error_changing_settings: '変更の保存中にエラーが発生しました。',
      add_domain_success: 'ドメインは正常に追加されました',
      add_domain_transfer_required: 'ドメインは別のアカウントに存在するため、ドメインの移管が必要です',
      add_domain_failure: 'ドメインを追加できませんでした',
      delete_domain_success: 'ドメインは正常に削除されました',
      delete_domain_failure: 'ドメインを削除できませんでした',
      delete_domain_failure_not_found: '削除するドメインが見つかりませんでした',
      post_syslog_success: 'Syslog は正常に追加されました',
      post_syslog_failure: 'Syslog を追加できませんでした',
      test_syslog_success: 'サーバーステータス:ポートにアクセス可能',
      test_syslog_failure: 'サーバーステータス:ポートにアクセスできません',
      delete_syslog_success: 'Syslog は正常に削除されました',
      delete_syslog_failure: 'Syslog を削除できませんでした',
      update_account_user_role_success: 'ユーザー「%s」のロールは正常に更新されました',
      add_update_account_users_success: '%s アカウントユーザーが正常に更新されました',
      add_update_account_users_failed: 'アカウントユーザーを更新できませんでした',
      get_email_continuity_settings_failed: 'Eメールの継続性設定を読み込めませんでした',
      update_email_continuity_settings_success: 'Eメールの継続設定が正常に更新されました',
      update_email_continuity_settings_failed: 'Eメールの継続設定を更新できませんでした',
      update_quarantine_notifications_settings_success: '検疫通知設定が正常に更新されました',
      update_quarantine_notifications_settings_failed: '隔離通知設定を更新できませんでした',
      update_policies_settings_success: 'ポリシー設定が正常に更新されました',
      update_policies_settings_failed: 'ポリシー設定を更新できませんでした',
      delete_account_user_success: 'ユーザー:「%s」は正常に削除されました',
      delete_account_users_success: '%s アカウントユーザーが正常に削除されました',
      login_as_user_failed: '「%s」としてログインできません',
      account_switch_failed: '「%s」アカウントに切り替えることができません',
      add_domain_error_duplicate: 'このドメイン名は既に追加されています。',
      add_domain_error_quarantine_notifier: '隔離通知設定を作成できませんでした',
      add_domain_error_blacklisted_domain_name: 'このドメイン名は追加できません',
      delete_domain_failure_quarantine_notifier:
        'ドメインは削除されましたが、関連する検疫通知設定を削除できませんでした',
      manage_domain_failure: '選択したドメインへの切り替えに失敗しました',
      manage_domain_failure_forbidden: '選択したドメインを管理するための十分な権限がありません',
      edit_domain_failure_forbidden: '選択したドメインを編集するための十分な権限がありません',
      get_domain_failure: 'ドメインデータの取得中にエラーが発生しました',
      wildcard_email_policy:
        '不正なメールが検出されました。ワイルドカードはサポートされていません。例えば、*@customer.comの代わりにcustomer.comを使用してください。',
      verify_domain_success: {
        verified_now: 'ありがとうございます。ドメイン %s の認証が完了しました。',
        email_sent: '確認手順が記載されたメールが %s に送信されました。',
        cpl_verification_started: '%s の検証が開始されました。これには数分かかる場合があります。'
      },
      verify_domain_failure: {
        mx_error: 'ドメインの MX レコードを確認できません',
        no_tech_email: 'このドメインの WHOIS 情報にメールアドレスが見つかりません。別の方法を試してください。',
        cname_error:
          '現在、ドメインの正しい CNAME レコードが見つかりません。エントリが DNS レコードに追加されていることを確認してください。ISP の設定によっては、変更が反映されるまでに最大 24 時間かかる場合があることに注意してください。',
        invalid_or_expired: 'ドメインが無効か、認証コードが期限切れです',
        verified_by_other_account:
          'このドメインはすでに追加され、別のアカウントで検証されています。このドメインがお客様のドメインである場合は、カスタマーサービスに連絡して詳細を問い合わせてください。',
        already_verified: 'ドメイン %s はすでに検証されています。',
        email_send_failure: 'メールメッセージを %s に送信できませんでした',
        tech_email_not_found: 'ドメイン %s の技術担当者の電子メールアドレスが見つかりませんでした',
        cpl_verification_failed: 'ドメインの検証に失敗しました。バラクーダネットワークスのサポートに連絡してください。',
        default: 'ドメインを検証できません'
      },
      add_mail_server_success: 'メールサーバーが保存されました',
      add_mail_server_failure: {
        default: 'メールサーバーを保存できませんでした',
        duplicate: '重複したメールサーバーが存在します。'
      },
      remove_mail_server_success: 'メールサーバーは削除されました',
      remove_mail_server_failure: {
        default: 'メールサーバーを削除できませんでした',
        not_found: 'メールサーバーは存在しません。'
      },
      update_mail_server_success: 'メールサーバーが更新されました',
      update_mail_server_failure: {
        default: 'メールサーバーを更新できませんでした',
        not_found: 'メールサーバーは存在しません。',
        duplicate: '重複したメールサーバーが存在します。'
      },
      test_mail_server_success: 'メッセージは %s@%s に正常に配信されました',
      test_mail_server_failure: {
        default: 'メッセージを配信できませんでした。',
        not_found: 'メールサーバーは存在しません。'
      },
      save_domain_settings_success: 'ドメイン設定は正常に保存されました',
      save_domain_settings_failure: 'ドメイン設定を保存できませんでした',
      error_subnet_too_large: '指定されたサブネット範囲が大きすぎます。複数の /24 サブネットに分割してください。',
      error_new_entry_single_ip: '新しいエントリは 1 つの IP 番号のみにできます',
      error_is_not_verified_domain: 'ドメイン名は検証済みのドメインでなければなりません。',
      error_already_specified: '指定されたサブネットは、別のアカウントのサブネットと競合しています',
      error_potentially_shared_infrastructure:
        '選択した IP アドレス/範囲は、潜在的に共有インフラストラクチャとしてマークされている範囲の一部です。これを特定のドメインに割り当てることはできません。',
      error_no_reverse_dns: '提供された IP アドレスにはリバースDNSがありません',
      error_conflict_subnet: '指定されたサブネットはサブネット:%s /%s と競合しています',
      row_error_subnet_too_large:
        '行 %s でエラーが発生しました (指定されたサブネット範囲が大きすぎます。複数の /24 サブネットに分割してください。)',
      row_error_new_entry_single_ip: '行 %s のエラー (新しいエントリは単一の IP 番号のみである可能性があります)',
      row_error_is_not_verified_domain: '行 %s のエラー (ドメイン名は検証済みのドメインでなければなりません)',
      row_error_already_specified:
        '行 %s のエラー (指定されたサブネットは、別のアカウントのサブネットと競合しています。)',
      row_error_potentially_shared_infrastructure:
        '行 %s でエラーが発生しました (選択した IP アドレス/範囲は、共有インフラストラクチャの可能性があるとマークされている範囲の一部です。これを特定のドメインに割り当てることはできません。)',
      row_error_no_reverse_dns: '行 %s でエラーが発生しました (指定した IP アドレスにはリバース DNS がありません)',
      row_error_conflict_subnet: '行 %s のエラー (指定されたサブネットはサブネットと重複しています:%s/%s)',
      save_domain_settings_unconfirmed_warning: '未確認の警告により更新が拒否されました',
      lookup_azure_ad_user: {
        success: 'Azure AD アカウントに存在する電子メールアドレス',
        failure: 'Azure AD アカウントでメールアドレスが見つかりませんでした',
        signed_url_error: 'Azure AD アカウントのクエリに失敗しました'
      },
      encryption_confirm_validation_success: 'ドメインは正常に検証されました。',
      encryption_confirm_validation_failure: 'ドメインを検証できませんでした。もう一度試してください。',
      encryption_logo_remove_success: 'ロゴは正常に削除されました。',
      encryption_validation_remove_success: '検証設定は削除されました。',
      encryption_cname_validation_success:
        'サブドメイン:%s を作成し、レコードを「encrypt.barracudanetworks.com」に指定してください。このレコードを作成したら、「検証を確認」をクリックします。',
      encryption_post_validation_success:
        'メールが %s に送信されました。このメールのリンクをたどった後、「検証を確認」をクリックして、Barracuda Email Encryption Service によるドメイン認証プロセスを完了してください。',
      encryption_validation_invalid: 'まず、CNAME または Postmaster で検証してください',
      encryption_failure: '現在、リクエストを行うことができませんでした。',
      ldap_get_sync_stats_failure: 'LDAP 同期統計を取得できませんでした',
      ldap_test_success: 'LDAP サーバーに正常に接続されました',
      ldap_test_failure: 'LDAP サーバーに接続できませんでした',
      ldap_serial_reset_success: 'シリアル番号の最大値が0にリセットされました',
      ldap_serial_reset_failure: 'LDAP で認識されているシリアル番号の最大値をリセットできませんでした',
      ldap_sync_success:
        'LDAP ユーザーが同期されました。%s ユーザーが更新されました。%s ユーザーが追加されました。%s ユーザーが削除されました。',
      ldap_sync_failure: 'LDAP 同期が失敗しました',
      edit_domain_form_has_errors: 'フォームにエラーがあります。訂正して保存し直してください。',
      reset_domain_settings_success: 'ドメイン設定をグローバルポリシーに正常にリセットしました',
      reset_domain_settings_failure: '設定を変更できません',
      save_and_apply_config: {
        backup_failure: '操作が失敗しました。後でもう一度試してください。',
        restore_success: '構成設定は正常に適用されました。',
        restore_failure: '無効または期限切れのトークン。復元できませんでした。'
      },
      invalid_domain: 'ドメイン名が無効です:%s',
      duplicate_email: 'Eメールが重複しています:%s',
      user_not_exist: 'ユーザーは存在しません:%s',
      invalid_email: 'Eメールが無効です:%s',
      verify_domain_transfer_completed: 'ドメイン %s は正常に移管されました。',
      verify_domain_transfer_success: 'ドメイン移管が開始されました',
      verify_domain_transfer_failure: 'ドメインの検証に失敗しました',
      delete_domain_move_success: 'ドメイン %s は正常に削除されました。',
      delete_domain_move_failure: 'ドメインを削除できませんでした',
      delete_domain_move_failure_not_found: 'ドメインが見つかりませんでした。',
      delete_domain_move_failure_unauthorized: '選択したドメインを削除するための十分な権限がありません',
      schedule_report_success: 'スケジュールが作成されました',
      schedule_report_updated_success: 'スケジュールが更新されました。変更内容は正常に保存されました。',
      schedule_report_deleted_success: 'スケジュールが削除されました',
      error_parsing_settings: '無効なJSON形式の設定',
      error_download_report: 'レポートのダウンロードに失敗しました'
    },
    data_tables: {
      message_log: {
        select: '[選択]',
        action: 'アクション',
        delivery: 'デリバリー',
        from: 'から',
        to: 'に',
        subject: '件名',
        date: '日付',
        size: 'サイズ',
        encryption: '暗号化',
        attachment: 'アタッチメント',
        reason: '理由',
        score: 'スコア'
      },
      audit_log: {
        timestamp: '日付',
        action: 'イベントタイプ',
        description: '[説明]',
        actor: 'ユーザ',
        ip: 'IP アドレス'
      },
      domains: {
        domain_name: '[ドメイン名]',
        status: 'ステータス',
        aliases: 'エイリアス',
        recipients: '受信者 (過去 30 日間)',
        mail_servers: 'メールサーバー',
        settings: '[設定]',
        domain_options: 'ドメインオプション'
      },
      atp_log: {
        status: 'ステータス',
        time: '時間',
        from: 'から',
        to: 'に',
        subject: '件名',
        file_name: '[ファイル名]',
        file_type: 'ファイルタイプ',
        report: '報告書',
        view_report: 'レポートを表示',
        no_records_available: '使用可能なレコードはありません'
      }
    },
    atp_log: {
      title: 'ATP ログ',
      domain: 'ドメイン',
      infected_attachments: '感染した添付ファイル',
      suspicious_attachments: '不審な添付ファイル',
      last_30_days: '過去 30 日間',
      status: 'ステータス',
      time: '時間',
      file_type: 'ファイルタイプ',
      export_button: 'CSV をダウンロード',
      search_placeholder: '送信者、受信者、件名、またはファイル名を検索する',
      all_domains: 'すべてのドメイン',
      all: 'すべてのファイルタイプ',
      filters: {
        1: '過去 24 時間',
        7: '過去 7 日間',
        30: '過去 30 日間',
        all: '[すべて]',
        clean: 'きれい',
        suspicious: '疑わしい',
        virus: '感染'
      },
      logStatuses: {
        all: '[すべて]',
        infected: '感染',
        clean: 'きれい',
        error: '[エラー]',
        suspicious: '疑わしい',
        future: 'スキャン中...'
      },
      export: {
        status: 'ステータス',
        origin: 'オリジン',
        fileName: '[ファイル名]',
        fileType: 'ファイルタイプ',
        messageId: 'メッセージ ID',
        from: 'から',
        to: 'に',
        time: '時間'
      },
      view_reports_modal: {
        title: 'E メール配信警告',
        content:
          'このメッセージには次の添付ファイルがあり、そのうちの 1 つ以上が高度な脅威であることが検出されました。感染した添付ファイルごとにレポートを確認してください。メッセージを配信することに決めたら、免責事項を確認して同意し、「メッセージを配信」をクリックします。',
        cancel: '[キャンセル]',
        deliver: '配信',
        checkbox_label:
          'バラクーダネットワークスは、このメッセージとそれに関連する添付ファイルの配信中または配信中に組織が受ける可能性のある脅威に関連するリスクを一切負わないことを認めます。',
        ui_loading: '添付データを読み込んでいます...',
        no_atp_attachment: 'ATP アタッチメントなし'
      }
    },
    audit_log: {
      title: '監査ログ',
      status: 'ステータス',
      time: '時間',
      file_type: 'ファイルタイプ',
      exportButton: 'CSV をエクスポート',
      search_placeholder: '検索',
      start_date: '開始日',
      end_date: '終了日',
      filter: {
        title: '[フィルター]',
        filter: '[フィルター]',
        event_type: 'イベントタイプ',
        apply: '申し込む',
        clear: 'クリア'
      },
      export: {
        to: 'に',
        time: '時間',
        ipAddress: 'IP アドレス',
        userName: 'ユーザ',
        description: '[説明]',
        eventType: 'イベントタイプ',
        formattedDate: '日付'
      },
      action: {
        select_all: 'すべて選択',
        move: '移動',
        delete: '[削除]',
        change: '分類済み',
        login: 'サインイン',
        logout: '[サインアウト]',
        log_action: 'ログアクション',
        create: '作成',
        delete_all: 'すべて削除',
        reset: 'リセット',
        enable: '有効にする',
        disable: '無効',
        suspend: '中断',
        notify: '通知',
        migrate_cpl: 'CPL をマイグレーション',
        restore: '復元',
        login_failure: 'サインインの失敗'
      },
      affected: {
        unavailable: 'ご利用いただけません',
        details_unavailable: '詳細はありません',
        require_password_for_quarantine_links: '隔離リンクに必要なパスワード',
        allow_quarantine_notification_interval_change: 'ユーザーが間隔を指定できるようにする',
        enable_quarantine: '隔離メッセージ',
        sender_policies: '送信者ポリシー',
        quarantine_notification_interval: '隔離通知間隔',
        created: 'ユーザー作成時間',
        sub_expires: 'サブスクリプション有効期限',
        sub_start_date: '購読開始日',
        visited: '訪問済み',
        password: '[パスワード]',
        smtp_servers: 'メールサーバー',
        message_content_filters: 'メッセージコンテンツフィルター',
        ip_policies: 'IP アドレスポリシー',
        geoip_policies: 'GeoIP ポリシー',
        spooling: 'スプーリング',
        spf_hardfail: '送信者ポリシーフレームワーク (SPF) ハードフェール',
        spf_softfail: '送信者ポリシーフレームワーク (SPF) ソフトフェール',
        cname_token: 'CNAME トークン',
        directory_services: 'ディレクトリサービス',
        dkim: 'ドメインキー識別メール (DKIM) アクション',
        azure_ad: 'アズール AD',
        sender_spoof: '送信者なりすまし対策',
        user_auto_add: 'ユーザーを自動的に追加',
        fastspam_scores: 'クラウドスキャンスコアリング',
        encryption_passtoken: '暗号化検証パストークン',
        encryption_vtype: '暗号化検証タイプ',
        ldap_auth: 'LDAP 認証コンフィギュレーション',
        ldap_sync: 'LDAP 同期設定',
        force_tls: 'TLS 経由の SMTP',
        srs: '送信者書き換えスキーム (SRS)',
        ptr: 'PTR レコードがない場合はブロック',
        bafi: '不正防止インテリジェンス',
        redirector_content_action: 'コンテンツインテント',
        atd: '高度な脅威対策',
        intent: '意図分析',
        brts: 'バラクーダリアルタイムシステム (BRTS)',
        brbl: 'バラクーダレピュテーションブロックリスト',
        fastspam_enabled: 'クラウドスキャン',
        encryption_verification: '暗号化検証',
        vscan: 'ウイルススキャン',
        ext_sender_warning: '外部送信者警告',
        linkprotect: 'リンク保護',
        default_user_exempt_enabled: '送信者の免除をユーザーに許可する',
        status: '購読状況',
        typosquat: 'タイポスクワッティング対策',
        outbound_message_content_filters: 'アウトバウンドメッセージコンテンツフィルタ',
        linked_domain_policy: 'インテントドメインポリシー',
        dmarc: 'ドメインベースのメッセージ認証 (DMARC)',
        dkim_exempt: 'DKIM 免除',
        atd_notify: 'ATP-管理者に通知',
        user_policies: '受信者ポリシー',
        ldap: 'LDAP 同期設定',
        encryption_confirmed: '暗号化の検証が確認されました',
        outbound_predefined_message_content_filters: '送信定義済みフィルター',
        spf_exempt: 'IP アドレスによる SPF 免除',
        spf_domain_exempt: 'ドメイン別の SPF 免除',
        language_policies: '言語ポリシー',
        atd_exempt_email: 'ATP-Eメールアドレス/ドメインによる免除',
        default_timezone: 'デフォルトタイムゾーン',
        bulk_email_detection: 'メールの一括検出',
        attachment_filter_policy: '添付ファイルフィルター',
        default_unmanaged_scan_policy: '管理対象外ユーザーのデフォルトポリシー',
        emailcat_marketing: 'マーケティング資料とニュースレター',
        default_user_deliver_enabled: 'エンドユーザーがブロックされたメッセージを表示して配信できるようにする',
        default_user_quar_deliver_enabled: 'エンドユーザーが隔離されたメッセージを表示および配信できるようにする',
        emailcat_listserver: 'メーリングリストの分類',
        emailcat_socialmedia: 'ソーシャルメディア分類',
        emailcat_corporate: '企業メール分類',
        emailcat_transactional: 'トランザクションメール分類',
        emergency_inbox_autoenabled: 'Eメールの継続',
        outbound_tls_domains: 'アウトバウンド TLS ドメイン',
        default_managed_scan_policy: '管理対象ユーザーのデフォルトポリシー',
        trusted_forwarders: '信頼できるフォワーダー',
        managed_domains: 'ユーザーが管理するドメイン',
        default_user_block_enabled: 'ユーザーが送信者をブロックすることを許可する',
        deleted: 'ドメインが削除されました',
        rate_control_exemptions: 'レートコントロール免除',
        saved_searches: '保存済み検索',
        rbls: 'レピュテーションブラックリスト (RBL)',
        atd_exempt_ip: 'ATP － 送信者 IP アドレスによる免除',
        dmarc_exempt: 'DMARC 免除',
        ptr_exempt: 'PTR 免除項目がない',
        outbound_spam_notification_address: 'アウトバウンドスパム通知アドレス',
        reject_notification_address: '拒否通知アドレス',
        rate_control: 'レートコントロール',
        alias_of: 'ドメインエイリアス',
        helpdesk_domains: 'ユーザーヘルプデスクドメイン',
        outbound_tagline: 'アウトバウンドタグライン/フッターテキスト',
        outbound_tagline_enabled: 'タグラインを追加',
        outbound_attachment_filter_policy: '送信添付ファイルフィルター',
        atd_admin_emails: 'ATP 通知電子メール',
        disable_account_rate_control: '内部レートコントロール',
        sender_notification_address: '隔離通知アドレス',
        sender_notification_enable: '検疫送信者通知',
        reject_notification_subject: '拒否通知件名',
        reject_notification_template: '拒否通知テンプレート',
        password_msoffice_filtering: 'パスワードで保護されたマイクロソフトオフィス文書',
        password_archive_filtering: 'パスワードで保護されたアーカイブフィルタリング',
        outbound_password_archive_filtering: 'アウトバウンドパスワード保護アーカイブフィルタリング',
        password_pdf_filtering: 'パスワードで保護された PDF',
        outbound_password_pdf_filtering: 'アウトバウンドパスワードで保護された PDF',
        outbound_predefined_message_content_filter_exceptions: 'アウトバウンド定義済みフィルタ例外',
        outbound_password_msoffice_filtering: 'アウトバウンドパスワードで保護された Microsoft Office ドキュメント',
        sender_notification_subject: '隔離通知件名',
        sender_notification_template: '検疫通知テンプレート',
        support_access_enabled: 'サポートアクセス',
        default_scan_policy: '既定の受信者ポリシー',
        message_body_access_enabled: 'メッセージへのパートナーアクセス',
        dismissed_notifications: '通知は却下されました',
        sub_override: 'バラクーダネットワークスによるサブスクリプションの無効化',
        brts_send_evidence: '不審なメッセージコンテンツをバラクーダセントラルに送信して分析してもらう',
        outbound_quarantine_start: '通知開始時間',
        outbound_quarantine_email: '通知アドレス',
        outbound_quarantine_interval: 'アウトバウンド検疫通知間隔',
        custom_interval: 'アウトバウンド検疫通知間隔',
        search_logs_all_domains: '(内部設定) すべてのドメインでメッセージログを検索できるようにする',
        azure_ad_ui: '(内部設定) Azure AD の設定を有効にする',
        domain_move: '(内部設定) セルフサービスドメイン移動を有効にする',
        trial_expiration_banner: '(内部設定) トライアルの有効期限バナーを有効にする',
        greylist_exempt: '(内部設定) アカウントのグレーリスト (疑わしい) 延期を無効にする',
        use_primary_smarthost: '(内部設定) プライマリスマートホストを使用する',
        auditlog: '(内部設定) 監査ログを有効にする',
        click_protection: '(内部設定) クリック防止設定を有効にする',
        config_copy: '(内部設定) アカウント設定のバックアップを有効にする',
        dmarc_ui: '(内部設定) DMARC 設定を有効にする',
        inbound_tagline: '(内部設定) 受信メールキャッチフレーズ',
        noblock_sender_response: '(内部設定) メールのサイレントブロックを有効にする',
        retention: '(内部設定) メールの保存期間',
        outbound_use_email_domain: '(内部設定) 送信メッセージにメールドメインを使用する',
        outbound_ndr_policy: '(内部設定) アウトバウンド NDR ポリシー',
        spoofing_enabled: '(内部設定) スプーフィング有効',
        max_message_size: '(内部設定) 最大メッセージサイズ',
        athena_stats: '(内部設定) Athena 受信者カウントを有効にする',
        disable_shared_ip_ranges: '(内部設定) ホスト型アウトバウンドリレー — EG.O365、グーグルアプリ、...',
        outbound_ip_partition_id: '(内部設定) 送信パーティションタイプ',
        skip_subdomains: '(内部設定) 子ドメインへのメールを拒否する',
        outbound_rate_control_block: '(内部設定) アウトバウンドユーザーレート制御ブロック',
        outbound_rate_control: '(内部設定) ドメインごとの管理対象外ユーザーレート制御',
        outbound_per_domain_rate_limit: '(内部設定) ユーザーごとの管理ユーザーレート制御',
        admins_msg_body_access: '(内部設定) 管理者がメールの内容と添付ファイルを表示できるようにする',
        disable_outbound_multilevel_intent_content_check:
          '(内部設定) 管理者がウェブページのコンテンツのアウトバウンドマルチレベルインテントチェックを無効にできるようにする',
        outbound_per_user_rate_limit: '(内部設定) アウトバウンドユーザーレート制御の免除',
        type: '(内部設定) ドメインタイプ (ESS/ハイブリッド)',
        end_user_react_ui: '(内部設定) エンドユーザー React UI',
        react_end_users: '(内部設定) エンドユーザー React UI',
        inline_deployment: 'インライン展開',

        allowOverride: 'ユーザーが間隔を指定できるようにする',
        inboundSchedule: '隔離通知間隔',
        customInboundSchedule: '通知間隔をスケジュールする',
        customOutboundSchedule: '通知間隔をスケジュールする',
        outboundEmailIds: '通知アドレス',
        outboundSchedule: 'アウトバウンド検疫通知間隔',
        allowWhitelist: '送信者の免除をユーザーに許可する',
        fmt_domain_moved: 'ドメイン %s を %s に移動しました',
        fmt_domain_from: 'ドメイン %s は %s からこのアカウントに移動されました',
        fmt_changed: '%s が変更されました',
        fmt_changed_for_domain: '%s がドメイン %s に変更されました',
        fmt_user_deleted: 'ユーザー %s は削除されました',
        fmt_outbound_ip_deleted: 'アウトバウンド IP %s が削除されました',
        fmt_deleted_for_domain: '%s はドメイン %s から削除されました',
        fmt_domain_deleted: 'ドメイン %s は削除されました',
        fmt_deleted: '%s が削除されました',
        fmt_user_logged_in: 'ユーザーは正常にログインしました',
        fmt_logged_in_domain: 'ユーザーはドメイン %s 管理に正常にログインしました',
        fmt_support_logged_in: 'バラクーダサポートが正常にログインしました',
        fmt_user_logged_out: 'ユーザーは正常にログアウトしました',
        fmt_user_logged_out_domain: 'ユーザーはドメイン %s 管理から正常にログアウトしました',
        fmt_support_logged_out: 'バラクーダサポートは正常にログアウトしました',
        fmt_atd_exported: 'ATD ログ行がエクスポートされました',
        fmt_message_log_exported: 'メッセージログ行がエクスポートされました',
        fmt_message_deleted: 'メッセージ %s は削除されました',
        fmt_message_sent_to_emailreg: 'メッセージ %s が EmailRegに送信されました',
        fmt_message_blocked: 'メッセージ %s はブロックされました',
        fmt_message_whitelisted: 'メッセージ %s がホワイトリストに登録されました',
        fmt_message_recategorized: 'メッセージ %s が再分類されました',
        fmt_message_delivered: 'メッセージ %s が配信されました',
        fmt_message_viewed: 'メッセージ %s が表示されました',
        fmt_message_marked_spam: 'メッセージ %s が迷惑メールとしてマークされました',
        fmt_message_marked_not_spam: 'メッセージ %s は迷惑メールではないとマークされました',
        fmt_message_rejected: 'メッセージ %s は拒否されました',
        fmt_setting_created: '%s が作成されました',
        fmt_user_created: 'ユーザー %s が作成されました',
        fmt_outbound_ip_created: 'アウトバウンド IP %s が作成されました',
        fmt_domain_created: 'ドメイン %s が作成されました',
        fmt_user_settings_deleted: 'ユーザー %s のすべての設定が削除されました',
        fmt_domain_users_deleted: 'ドメイン %s のすべてのユーザーが削除されました',
        fmt_account_outbound_ips_deleted: 'アカウント %s のドメイン %s のすべてのアウトバウンド IP が削除されました',
        fmt_list_outbound_ips_deleted: 'リスト [%s] のすべてのアウトバウンド IP が削除されました',
        fmt_domain_settings_deleted: 'ドメイン %s のすべての設定が削除されました',
        fmt_account_settings_deleted: 'アカウント %s のすべての設定が削除されました',
        fmt_domain_settings_reset_account_level:
          'ドメイン %s のドメイン設定がアカウントレベルの設定にリセットされました',
        fmt_login_info_sent: 'ログイン情報が <strong>%s</strong> に送信されました',
        fmt_account_ended: 'アカウント終了',
        fmt_account_disabled: 'アカウント無効',
        fmt_account_suspended: 'アカウントが停止されました',
        fmt_account_migrated_to_cpl: 'アカウントが CPL に移行されました',
        fmt_user_notified_via_email: 'ユーザー %s は電子メールで通知されました',
        fmt_domain_settings_restored: 'ドメイン %s のドメイン設定 [%s] が復元されました',
        fmt_login_failure: 'ログイン失敗'
      }
    },
    atp_settings: {
      title: '高度な脅威対策',
      enable_advanced_threat_protection: '高度な脅威対策を有効にする',
      deliver_scan: '最初に配信、次にスキャン',
      scan_deliver: '最初にスキャン、次に配信',
      no: 'いいえ',
      disabling_antivirus: 'アンチウイルスを無効にすると、高度な脅威対策も無効になります',
      notify_admin: '管理者に通知',
      yes: 'はい',
      admin_emails: 'ATP 通知電子メール',
      atp_exemptions: 'ATP 免除',
      atp_exemptions_note:
        '注意:ここに記載されているエントリには、ATP サービスでスキャンされた添付ファイルはありません。',
      exemptions_by_email_addresses_domains: 'メールアドレス/ドメインによる免除',
      exemptions_by_email_addresses_domains_short: '免除 (Eメールアドレス/ドメイン)',
      exemptions_by_ip_addresses: '送信者 IP アドレスによる免除',
      exemptions_by_ip_addresses_short: '免除 (送信者 IP アドレス)',
      netmask: 'ネットマスク',
      anti_virus_disabled:
        'ウイルススキャンが無効になっているため、高度な脅威対策が無効になっています。<br></br> 受信設定] -> [アンチスパム/アンチウイルス] ページで [電子メールのウイルススキャン] を [はい] に設定してください。',
      error: {
        empty_email: '有効なEメールアドレスを入力してください。',
        invalid_email: 'メールアドレス {email} は有効ではありません。',
        empty_ip_address: '有効な IP アドレスを入力してください。',
        invalid_ip_address: 'IP アドレスが無効です',
        empty_netmask: '有効なネットマスクを入力してください。',
        invalid_netmask: 'ネットマスクが無効です。'
      }
    },
    account_management: {
      title: 'アカウント管理',
      header: 'システムログ統合',
      hostname: 'IP アドレス/ホスト名',
      port: 'ポート',
      helper_text: '正常に接続するには TCP+TLS が必要です。TLS 以外はサポートされていません。',
      add: '追加',
      test: 'テスト',
      delete: '[削除]',
      specify_hostname: 'ホスト名を指定してください。',
      specify_port: 'ポートを指定してください。',
      hostname_info: '正常に接続するには TCP+TLS が必要です。TLS 以外はサポートされていません。',
      error_missing_hostname: '行 {row} のエラー (ホスト名が無効です)',
      error_invalid_hostname: '行 {row} のエラー (ホスト名が無効です)',
      error_missing_port: '行 {row} のエラー (ポートが無効です)',
      error_invalid_port: '行 {row} のエラー (ポートが無効です)'
    },
    oauth2: {
      process: {
        start_failed: '認証プロセスの開始に失敗しました'
      },
      auth_code_flow: {
        start_failed: '認証コードフローの開始に失敗しました。',
        token_exchange_failed: '認証コードの交換に失敗した。',
        create_session_failed: '認証に失敗しました。'
      },
      exception: {
        OAuth2AuthorizeParameterMissing: '必須パラメータが提供されていない。',
        OAuth2AuthorizationCodeRequestFailed: '認証コードのリクエストに失敗しました。',
        OAuth2IdentityProviderFlowFailed: '認証フローの実行中にエラーが発生しました。',
        OAuth2TokenExchangePKCEVerificationFailed: 'トークン交換コードの検証に失敗しました。',
        IdTokenGenerationFailed: 'IDトークンの生成中にエラーが発生しました。',
        EGDIdentityNotFound: '申し訳ありませんが、そのメールアドレスは有効なユーザーとして認識されません。',
        OAuth2AuthorizeRedirectURINotAllowed: '指定されたリダイレクトURIは許可されていません',
        azure_ad_api_token_failed: 'トークン交換に失敗した。',
        azure_ad_api_env_missing: 'リクエストの処理中にエラーが発生しました。',
        ms_invalid_request: '認証要求が無効でした。',
        ms_unauthorized_client: 'クライアント・アプリケーションは認証コードを要求することを許可されていない。',
        ms_access_denied: '資源所有者は同意を拒否した。',
        ms_unsupported_response_type: '認証サーバーがリクエストのレスポンス・タイプをサポートしていない。',
        ms_server_error: 'サーバーで予期せぬエラーが発生しました。',
        ms_temporarily_unavailable: 'サーバーが一時的にビジー状態で、リクエストを処理できない。',
        ms_invalid_resource:
          'ターゲットリソースが存在しないか、Microsoft Entra ID が見つからないか、または正しく構成されていないため、ターゲットリソースが無効です。',
        ms_login_required: 'ユーザーが多すぎるか、見つからない。',
        ms_interaction_required: 'このリクエストはユーザーとの対話を必要とする。',
        ms_invalid_grant: '認証コードまたは PKCE コード・ベリファイアが無効であるか、有効期限が切れている。',
        ms_invalid_client: 'クライアント認証に失敗しました。',
        ms_unsupported_grant_type: '認証サーバーが認証グラント・タイプをサポートしていない。',
        ms_consent_required: 'このリクエストにはユーザーの同意が必要です。',
        ms_invalid_scope: 'アプリが要求したスコープは無効です。',
        tdf_invalid_domain: '申し訳ありませんが、そのドメインは有効なドメインとして認識されません。',
        tdf_domain_not_verified: '申し訳ありませんが、そのドメインは確認されていません。',
        azure_ad_api_error_token_failed: '認証の試みは拒否された。',
        OAuth2ProcessNotFound: '認証リクエストの有効期限が切れました。再度サインインをお試しください。'
      }
    }
  }
}
