import React from 'react'
import { Route, Routes } from 'react-router-dom'

import AccountManagement from 'components/pages/accountManagement/AccountManagement'
import routesConfig, { appAuth0LoginCallbackPath, UiRoute } from 'lib/routesConfig'

import Layout from 'components/libs/layout/Layout'
import PrivateRoute from 'components/libs/routes/PrivateRoute'
import PublicRoute from 'components/libs/routes/PublicRoute'

import MessageLogPage from 'components/pages/messageLog/MessageLogPage'
import QNDigestLinksHandler from 'components/pages/messageLog/QNDigestLinksHandler'
import Dashboard from 'components/pages/overview/dashboard/Dashboard'
import MessageLog from 'components/pages/messageLog/MessageLog'
import AuditLog from 'components/pages/auditLog/AuditLog'
import AtpLog from 'components/pages/atpLog/AtpLog'
import OutboundQuarantine from 'components/pages/outboundQuarantine/OutboundQuarantine'
import LicenseContent from 'components/pages/license/LicenseContent'
import MessageDetail from 'components/pages/messageDetail/MessageDetail'
import ChangePassword from 'components/pages/settings/changePassword/ChangePassword'
import LinkedAccounts from 'components/pages/settings/linkedAccounts/LinkedAccounts'
import UserQuarantineNotification from 'components/pages/settings/quarantineNotification/QuarantineNotification'
import UserSenderPolicies from 'components/pages/settings/senderPolicies/SenderPolicies'
import Support from 'components/pages/support/Support'
import Help from 'components/pages/support/help/Help'
import Login from 'components/pages/login/Login'
import LoginSSO from 'components/pages/login/sso/LoginSSO'
import Auth0LoginCallback from 'components/pages/login/auth0LoginCallback/Auth0LoginCallback'
import AutoLogin from 'components/pages/actions/autoLogin/AutoLogin'
import AzureSSO from 'components/pages/azureSSO/AzureSSO'
import BccLogin from 'components/pages/bccLogin/BccLogin'
import BulkEdit from 'components/pages/settings/bulkEdit/BulkEdit'
import VerifyLinkAccount from 'components/pages/actions/verifyLinkAccount/VerifyLinkAccount'
import ResetPassword from 'components/pages/actions/resetPassword/ResetPassword'
import OnboardWizard from 'components/pages/onboardWizard/OnboardWizard'
import UnregisteredPage from 'components/pages/unregisteredPage/UnregisteredPage'
import Domains from 'components/pages/domains/Domains'
import UsersList from 'components/pages/users/usersList/UsersList'
import DefaultPolicy from 'components/pages/users/defaultPolicy/DefaultPolicy'
import AddUpdateUsers from 'components/pages/users/addUpdateUsers/AddUpdateUsers'
import QuarantineNotification from 'components/pages/users/quarantineNotification/QuarantineNotification'
import EmailContinuity from 'components/pages/users/emailContinuity/EmailContinuity'
import Reputation from 'components/pages/inboundSettings/reputation/Reputation'
import CustomRbls from 'components/pages/inboundSettings/customRbls/CustomRbls'
import RateControl from 'components/pages/inboundSettings/rateControl/RateControl'
import IpPolicies from 'components/pages/inboundSettings/ipPolicies/IpPolicies'
import RegionalPolicies from 'components/pages/inboundSettings/regionalPolicies/RegionalPolicies'
import RecipientPolicies from 'components/pages/inboundSettings/recipientPolicies/RecipientPolicies'
import ContentPolicies from 'components/pages/inboundSettings/contentPolicies/ContentPolicies'
import AntiPhishing from 'components/pages/inboundSettings/antiPhishing/AntiPhishing'
import MachineLearning from 'components/pages/inboundSettings/machineLearning/MachineLearning'
import SenderPolicies from 'components/pages/inboundSettings/senderPolicies/SenderPolicies'
import SenderAuth from 'components/pages/inboundSettings/senderAuth/SenderAuth'
import SenderIp from 'components/pages/outboundSettings/senderIp/SenderIp'
import Tagline from 'components/pages/outboundSettings/tagline/Tagline'
import Encryption from 'components/pages/outboundSettings/encryption/Encryption'
import OutboundContentPolicies from 'components/pages/outboundSettings/contentPolicies/ContentPolicies'
import Notifications from 'components/pages/outboundSettings/notifications/Notifications'
import AdminBulkEdit from 'components/pages/adminBulkEdit/AdminBulkEdit'
import AtpSettings from 'components/pages/atpSettings/AtpSettings'
import ReportList from 'components/pages/reports/reportList/ReportList'
import ScheduledReports from 'components/pages/reports/scheduledReports/ScheduledReports'
import VerifyDomain from 'components/pages/domains/verifyDomain/VerifyDomain'
import UniqueDomainRecipients from 'components/pages/domains/uniqueDomainRecipients/UniqueDomainRecipients'
import EditDomain from 'components/pages/domains/editDomain/EditDomain'
import SenderIpBulkEdit from 'components/pages/outboundSettings/senderIp/SenderIpBulkEdit/SenderIpBulkEdit'
import VerifyDomainTransfer from 'components/pages/domains/verifyDomainTransfer/VerifyDomainTransfer'
import OAuth2Callback from 'components/pages/oauth2/OAuth2Callback'
import OAuth2Authorize from 'components/pages/oauth2/OAuth2Authorize'
import EmailLog from 'components/pages/emailLog/EmailLog'

// cudaspt
import CudasptLogin from 'components/pages/cudaspt/login/Login'
import Accounts from 'components/pages/cudaspt/accounts/Accounts'

// test
import TestBDSV2 from 'components/pages/test/TestBDSV2'

export type RouteParams = [UiRoute, React.FC]
export { appAuth0LoginCallbackPath }

const publicRoutes: RouteParams[] = [
  // login
  [routesConfig.LOGIN, Login],
  [routesConfig.LOGIN_SSO, LoginSSO],
  [routesConfig.AUTO_LOGIN, AutoLogin],
  [routesConfig.AZURE_SSO, AzureSSO],
  [routesConfig.BCC_LOGIN, BccLogin],
  [routesConfig.CUDASPT_LOGIN, CudasptLogin],
  [routesConfig.AUTH0_LOGIN_CALLBACK, Auth0LoginCallback],
  [routesConfig.OAUTH2_CALLBACK, OAuth2Callback],
  [routesConfig.OAUTH2_AUTHORIZE, OAuth2Authorize],

  // verify link account
  [routesConfig.VERIFY_LINK_ACCOUNT, VerifyLinkAccount],
  [routesConfig.RESET_PASSWORD, ResetPassword],

  // test
  [routesConfig.TEST_BDSV2, TestBDSV2]
]

const privateRoutes: RouteParams[] = [
  // Overview
  [routesConfig.OVERVIEW_DASHBOARD, Dashboard],
  [routesConfig.OVERVIEW_MESSAGE_LOG, MessageLog],
  [routesConfig.OVERVIEW_ATP_LOG, AtpLog],
  [routesConfig.OVERVIEW_OUTBOUND_QUARANTINE, OutboundQuarantine],
  [routesConfig.OVERVIEW_AUDIT_LOG, AuditLog],
  [routesConfig.OVERVIEW_LICENSE_COMPLIANCE, LicenseContent],

  // Inbound Settings
  [routesConfig.INBOUND_SETTINGS_REPUTATION, Reputation],
  [routesConfig.INBOUND_SETTINGS_CUSTOM_RBLS, CustomRbls],
  [routesConfig.INBOUND_SETTINGS_CUSTOM_RBLS_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_RATE_CONTROL, RateControl],
  [routesConfig.INBOUND_SETTINGS_RATE_CONTROL_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_IP_POLICIES, IpPolicies],
  [routesConfig.INBOUND_SETTINGS_IP_POLICIES_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_TRUSTED_FORWARDERS_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_REGIONAL_POLICIES, RegionalPolicies],
  [routesConfig.INBOUND_SETTINGS_GEOIP_POLICIES_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_GEOIP_EMAIL_DOMAIN_EXEMPT_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_GEOIP_IPADDRESS_EXEMPT_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_LANGUAGE_POLICIES_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_RECIPIENT_POLICIES, RecipientPolicies],
  [routesConfig.INBOUND_SETTINGS_RECIPIENT_POLICIES_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_SENDER_POLICIES, SenderPolicies],
  [routesConfig.INBOUND_SETTINGS_SENDER_POLICIES_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_SENDER_AUTH, SenderAuth],
  [routesConfig.INBOUND_SETTINGS_DMARC_EXEMPT_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_DKIM_EXEMPT_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_SPF_EXEMPT_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_SPF_DOMAIN_EXEMPT_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_SPF_MISSING_EXEMPT_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_PTR_EXEMPT_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_CONTENT_POLICIES, ContentPolicies],
  [routesConfig.INBOUND_SETTINGS_ATTACHMENT_FILTERS_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_MESSAGE_CONTENT_FILTERS_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_ANTI_PHISHING, AntiPhishing],
  [routesConfig.INBOUND_SETTINGS_MACHINE_LEARNING, MachineLearning],
  [routesConfig.INBOUND_SETTINGS_MACHINE_LEARNING_EXEMPT_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_SETTINGS_INTENT_DOMAINS_BULK_EDIT, AdminBulkEdit],

  // Outbound Settings
  [routesConfig.OUTBOUND_SETTINGS_SENDER_IP, SenderIp],
  [routesConfig.OUTBOUND_SETTINGS_SENDER_IP_BULK_EDIT, SenderIpBulkEdit],
  [routesConfig.OUTBOUND_SETTINGS_TAGLINE, Tagline],
  [routesConfig.OUTBOUND_SETTINGS_ENCRYPTION, Encryption],
  [routesConfig.OUTBOUND_SETTINGS_ENCRYPTION_BULK_EDIT, AdminBulkEdit],
  [routesConfig.OUTBOUND_SETTINGS_CONTENT_POLICIES, OutboundContentPolicies],
  [routesConfig.OUTBOUND_SETTINGS_ATTACHMENT_FILTERS_BULK_EDIT, AdminBulkEdit],
  [routesConfig.OUTBOUND_SETTINGS_MESSAGE_CONTENT_FILTERS_BULK_EDIT, AdminBulkEdit],
  [routesConfig.OUTBOUND_SETTINGS_PREDEFINED_FILTERS_BULK_EDIT, AdminBulkEdit],
  [routesConfig.OUTBOUND_SETTINGS_PREDEFINED_FILTERS_EXCEPTIONS_BULK_EDIT, AdminBulkEdit],
  [routesConfig.OUTBOUND_SETTINGS_NOTIFICATIONS, Notifications],

  // Domains
  [routesConfig.DOMAINS, Domains],
  [routesConfig.VERIFY_DOMAIN, VerifyDomain],
  [routesConfig.UNIQUE_DOMAIN_RECIPIENTS, UniqueDomainRecipients],
  [routesConfig.EDIT_DOMAIN, EditDomain],
  [routesConfig.INBOUND_TLS_DOMAINS_BULK_EDIT, AdminBulkEdit],
  [routesConfig.INBOUND_TLS_DOMAIN_EXEMPTIONS_BULK_EDIT, AdminBulkEdit],
  [routesConfig.VERIFY_DOMAIN_TRANSFER, VerifyDomainTransfer],

  // users
  [routesConfig.USERS_LIST, UsersList],
  [routesConfig.USERS_DEFAULT_POLICY, DefaultPolicy],
  [routesConfig.USERS_ADD_UPDATE_USERS, AddUpdateUsers],
  [routesConfig.USERS_QUARANTINE_NOTIFICATION, QuarantineNotification],
  [routesConfig.USERS_EMAIL_CONTINUITY, EmailContinuity],

  // Reports
  [routesConfig.REPORT_LIST, ReportList],
  [routesConfig.SCHEDULED_REPORTS, ScheduledReports],

  // MessageLog
  [routesConfig.QN_DIGEST_LINKS_HANDLER, QNDigestLinksHandler],
  [routesConfig.MESSAGE_LOG, MessageLogPage],
  [routesConfig.MESSAGE_DETAIL, MessageDetail],

  // Settings
  [routesConfig.SETTINGS_QUARANTINE_NOTIFICATION, UserQuarantineNotification],
  [routesConfig.SETTINGS_LINKED_ACCOUNTS, LinkedAccounts],
  [routesConfig.SETTINGS_SENDER_POLICY, UserSenderPolicies],
  [routesConfig.SETTINGS_CHANGE_PASSWORD, ChangePassword],
  [routesConfig.SENDER_POLICIES_BULK_EDIT, BulkEdit],

  // management account
  [routesConfig.ACCOUNT_MANAGEMENT, AccountManagement],

  // Support
  [routesConfig.SUPPORT, Support],
  [routesConfig.SUPPORT_HELP_SEEN_RECIPIENTS, Help],
  [routesConfig.SUPPORT_HELP_DASHBOARD, Help],
  [routesConfig.SUPPORT_HELP_COMPLIANCE, Help],
  [routesConfig.SUPPORT_HELP_ADMIN_LOG, Help],
  [routesConfig.SUPPORT_HELP_ATP_LOG, Help],
  [routesConfig.SUPPORT_HELP_AUDIT_LOG, Help],
  [routesConfig.SUPPORT_HELP_DOMAIN_VERIFICATION, Help],
  [routesConfig.SUPPORT_HELP_MAIL_SERVER_TEST, Help],
  [routesConfig.SUPPORT_HELP_DOMAIN_MANAGER, Help],
  [routesConfig.SUPPORT_HELP_DOMAIN_SETTINGS, Help],
  [routesConfig.SUPPORT_HELP_RECIPIENT_LIST, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_ANTI_VIRUS, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_CUSTOM_RBLS, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_IP_ADDRESS_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_REGIONAL_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_RECIPIENT_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_SENDER_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_SENDER_AUTHENTICATION, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_CONTENT_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_RATE_CONTROL, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_ANTI_PHISHING, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_EMAIL_BANNERS, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_ATP, Help],
  [routesConfig.SUPPORT_HELP_INBOUND_MACHINE_LEARNING, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_SENDER_IP, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_ANTI_VIRUS, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_TAGLINE, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_DLP, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_CONTENT_POLICIES, Help],
  [routesConfig.SUPPORT_HELP_SENDER_NOTIFICATION, Help],
  [routesConfig.SUPPORT_HELP_OUTBOUND_QUARANTINE_INBOX, Help],
  [routesConfig.SUPPORT_HELP_USERS_DEFAULT_POLICY, Help],
  [routesConfig.SUPPORT_HELP_USERS_EDIT, Help],
  [routesConfig.SUPPORT_HELP_USERS_EMAIL_CONTINUITY, Help],
  [routesConfig.SUPPORT_HELP_USERS_QUARANTINE, Help],
  [routesConfig.SUPPORT_HELP_USERS_LIST, Help],
  [routesConfig.SUPPORT_HELP_LINKED_ACCOUNTS, Help],
  [routesConfig.SUPPORT_HELP_ENDUSER_SENDER_POLICY, Help],
  [routesConfig.SUPPORT_HELP_ENDUSER_QUARANTINE_NOTIFICATION, Help],
  [routesConfig.SUPPORT_HELP_ENDUSER_LOG, Help],
  [routesConfig.SUPPORT_HELP_SELF_SERVICE1, Help],
  [routesConfig.SUPPORT_HELP_SELF_SERVICE2, Help],
  [routesConfig.SUPPORT_HELP_SELF_SERVICE3, Help],
  [routesConfig.SUPPORT_HELP_SELF_SERVICE4, Help],
  [routesConfig.SUPPORT_HELP_SELF_SERVICE5, Help],
  [routesConfig.SUPPORT_HELP_REGEX, Help],
  [routesConfig.SUPPORT_HELP_REPORTS_DOMAIN, Help],
  [routesConfig.SUPPORT_HELP_REPORTS_ALL_DOMAIN, Help],
  [routesConfig.SUPPORT_HELP_SYSLOG_INTEGRATION, Help],

  // Onboard Wizard
  [routesConfig.ONBOARD_WIZARD, OnboardWizard],

  // Cudaspt
  [routesConfig.CUDASPT_ACCOUNTS, Accounts],

  // ATP settings
  [routesConfig.ATP_SETTINGS, AtpSettings],

  // Email log
  [routesConfig.EMAIL_LOG, EmailLog]
]

const AppRoutes = (
  <Layout>
    <Routes>
      {/* Public routes */}
      {publicRoutes
        .filter(routeparams => routeparams[0])
        .map(routeParams => {
          const [routeConfig, Component] = routeParams

          return (
            <Route
              key={routeConfig.id}
              path={routeConfig.path}
              element={<PublicRoute route={routeConfig} Component={Component} />}
            />
          )
        })}

      {/* Private routes */}
      {privateRoutes
        .filter(routeparams => routeparams[0])
        .map(routeParams => {
          const [routeConfig, Component] = routeParams

          return (
            <Route
              key={routeConfig.id}
              path={routeConfig.path}
              element={<PrivateRoute route={routeConfig} Component={Component} />}
            />
          )
        })}

      {/* 404 route */}
      <Route path="*" element={<PrivateRoute Component={UnregisteredPage} />} />
    </Routes>
  </Layout>
)

export default AppRoutes
