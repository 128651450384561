import axios, { AxiosPromise } from 'axios'

import config from 'config/appConfig'
import reduxStore from 'lib/reduxStore'

const CONTENT_TYPE = 'application/json'
const EXPIRES = '-1'
const CACHE_CONTROL = 'no-cache,no-store,must-revalidate,max-age=-1,private'
const DEFAULT_TIMEOUT = 30000 // 30 sec

export const azureAdApi = axios.create()
export const PAMApi = axios.create()

axios.defaults.baseURL = config.GATEWAY_URL

interface Request {
  method: any

  path(params: Record<string, unknown>): string
}

interface Options {
  data?: any // body values
  params?: Record<string, unknown> // query params
  urlParams?: Record<string, unknown> // url params
  auth?: LoginCredentials // basic auth header
  headers?: Record<string, unknown>
  callbacks?: Record<string, unknown> // upload, download progress
  timeout?: number // timeout in milliseconds
}

export interface LoginCredentials {
  user_id?: string
  password?: string
  session_id?: string

  // For Azure SSO login
  state?: string
  code?: string
  session_state?: string

  // For auto-login
  account_id?: string
  hash?: string
  expiration?: string
  source?: string
}

export interface ChangePasswordCredentials {
  newPassword: string
  oldPassword: string
}

export interface ResetPasswordCredentials {
  userId: string
  password: string
  hash: string
  expiration: string
}

export interface ApiSuccessResponse<Data> {
  data: Data
}

export interface ApiSuccessReportWithOffset<Report> {
  report: Report
  offset: number
}

export interface ApiRejectResponse<T = string> {
  rejectValue: T
}

// interceptors
let requestInterceptors: any = null
let responseInterceptors: any = null

// TODO: add host check when deployed
export function setRequestInterceptors(requestMiddleware: any): void {
  requestInterceptors = axios.interceptors.request.use(requestMiddleware as any)
}

export function setResponseInterceptors(responseData?: any, responseError?: any): void {
  responseInterceptors = axios.interceptors.response.use(responseData, error =>
    responseError ? responseError(error.response) : Promise.reject(error.response)
  )
}

export function ejectRequestInterceptor(): void {
  axios.interceptors.request.eject(requestInterceptors)
  requestInterceptors = null
}

export function ejectResponseInterceptor(): void {
  axios.interceptors.response.eject(responseInterceptors)
  responseInterceptors = null
}

// api errors
export function validateApiError(e: any) {
  if (!e) {
    return config.DEFAULT_ERROR_MESSAGE
  }

  switch (true) {
    case e === 'mstore_search_with_user_id_only_supported_for_domains':
      return config.API_ERRORS.UNSUPPORTED_MSTORE_SEARCH_WITH_USER_ID
    default:
      return config.DEFAULT_ERROR_MESSAGE
  }
}

// REST interface
export default function restClient(request: Request, options: Options = {}): AxiosPromise<any> {
  const { path, method } = request
  const { urlParams, callbacks, ...requestOptions } = options
  const store = reduxStore.getState()
  const accessToken = store.auth?.session?.accessToken || store.auth?.accessToken

  const fixedUrlParams = {
    ...(accessToken && {
      bccAccountId: store.auth?.accessTokenObject?.bccAccountId,
      domainId: store.auth?.accessTokenObject?.domainId,
      wizardDomainId: store.emailServer?.emailServer?.domainId,
      userId: store.auth?.accessTokenObject?.userId,
      bccUserId: store.auth?.accessTokenObject?.userId,
      accountId: store.auth?.accessTokenObject?.accountId
    }),
    ...urlParams
  }

  const url = path(fixedUrlParams || {})

  const requestParams: any = {
    ...requestOptions,
    headers: {
      'Content-Type': CONTENT_TYPE,
      Accept: CONTENT_TYPE,
      Expires: EXPIRES,
      'Cache-Control': CACHE_CONTROL,
      ...(requestOptions.headers || {})
    },
    method,
    url,
    timeout: requestOptions.timeout || DEFAULT_TIMEOUT,
    ...callbacks
  }

  return axios(requestParams)
}
