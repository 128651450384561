import { MetadataResponse } from 'types/general'
import { UpdateAccountSettingsPayload, UpdateDomainSettingsPayload } from 'redux/features/settings/settingsApiThunks'

export interface Metadata {
  accountId: string
  bccAccountId: string
  domainId: number
  userId: string
}

export enum PolicyStatus {
  block = 'block',
  exempt = 'exempt',
  quarantine = 'quarantine'
}

export interface Policy {
  comment: string
  id: string
  modified: number
  name: string
  policy: PolicyStatus
  isDisabled?: boolean
  isConflict?: number
}

export const HOURS = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] as const
export type Hour = (typeof HOURS)[number]

export const DAYS = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'] as const
export const SHORT_DAYS = [1, 2, 3, 4, 5, 6, 7]
export type Day = (typeof DAYS)[number]
export type ShortDay = (typeof SHORT_DAYS)[number]

export type InboundScheduleValue = Hour[]
export type InboundSchedule = {
  [K in Day]: InboundScheduleValue
}

export type InboundScheduleUIValue = { [K in Hour]?: true }
export type InboundScheduleUI = {
  [K in Day]: InboundScheduleUIValue
}

export enum InboundScheduleType {
  never = 'never',
  custom = 'custom'
}

export interface InboundScheduleReport {
  allowWhitelist: number
  created: string | null
  customInboundSchedule: InboundSchedule
  inboundSchedule: InboundScheduleType
  modified: string | null
  timezone: string
}

export interface LinkedAccount {
  email: string
  id?: string
  verificationPending?: boolean
}

export interface AccountPermission {
  allowQuarantineNotificationIntervalChange: number
  defaultTimezone: string
  defaultUserBlockEnabled: number
  defaultUserDeliverEnabled: number
  defaultUserExemptEnabled: number
  defaultUserQuarDeliverEnabled: number
  emergencyInboxEnabled: number
  searchLogsAllDomainsLimit: number
  hasForensicsAccess: boolean
  hasInferenceAccess: boolean
}

export interface QuarantineNotificationReport {
  metadata: Metadata
  results: InboundScheduleReport[]
}

export interface QuarantineNotificationUI {
  inboundSchedule: InboundScheduleType
  customInboundSchedule: InboundScheduleUI
}

export interface SenderPoliciesReport {
  itemsTotal: number
  metadata: Metadata
  pageNum: number
  pagesTotal: number
  results: Policy[]
  resultsCount: number
}

export interface SenderPoliciesResponse {
  data: SenderPoliciesReport
  metadata: MetadataResponse
}

export interface LinkedAccountsReport {
  metadata: Metadata
  results: LinkedAccount[]
}

export interface AccountPermissionsReport {
  metadata: Metadata
  results: AccountPermission[]
}

export type SettingsObject = {
  [key in AvailableSettings]?: Record<string, any> | string | null
}

export interface EmailCategories {
  name: string
  variable: AvailableSettings
  default: string
  description: string
  recommended: string
}

export enum AvailableSettings {
  ACCOUNT_NAME = 'account_name',
  DEFAULT_USER_EXEMPT_ENABLED = 'default_user_exempt_enabled',
  DEFAULT_USER_BLOCK_ENABLED = 'default_user_block_enabled',
  DEFAULT_USER_DELIVER_ENABLED = 'default_user_deliver_enabled',
  DEFAULT_USER_QUAR_DELIVER_ENABLED = 'default_user_quar_deliver_enabled',
  SENDER_POLICIES = 'sender_policies',
  FORCED_LOGIN = 'require_password_for_quarantine_links',
  EMERGENCY_INBOX_ENABLED = 'emergency_inbox_enabled',
  EMERGENCY_INBOX_ENABLED_CHANGE = 'emergency_inbox_enabled_change',
  DEFAULT_TIMEZONE = 'default_timezone',
  ALLOW_QUARANTINE_NOTIFICATION_INTERVAL_CHANGE = 'allow_quarantine_notification_interval_change',
  SEARCH_LOGS_ALL_DOMAINS = 'search_logs_all_domains',
  SEARCH_LOGS_ALL_DOMAINS_LIMIT = 'search_logs_all_domains_limit',
  HAS_FORENSICS_ACCESS = 'has_forensics_access',
  REGION = 'region',
  TYPE = 'type',
  WIZARD_SETTINGS = 'wizard_settings',
  WIZARD_COMPLETE = 'wizard_complete',
  WIZARD_FLAG = 'wizard_flag',
  HAS_MAIL_FLOWN = 'has_mail_flown',
  ATD = 'atd',
  ATD_NOTIFY = 'atd_notify',
  ATD_ADMIN_EMAILS = 'atd_admin_emails',
  O365 = 'o365',
  SUB_EXPIRES = 'sub_expires',
  USER_COUNT = 'user_count',
  ATD_SUBSCRIPTION = 'atd_subscription',
  DISABLE_SHARED_IP_RANGES = 'disable_shared_ip_ranges',
  SERIAL = 'serial',
  LINKING_CODE = 'linking_code',
  BILLING_TYPE = 'billing_type',
  END_DATE = 'end_date',
  END_MONTH = 'end_month',
  END_YEAR = 'end_year',
  REACT_WIZARD_FLAG = 'react_wizard_flag',
  SMTP_SERVERS = 'smtp_servers',
  BAFI = 'bafi',
  EXT_SENDER_WARNING = 'ext_sender_warning',
  BRBL = 'brbl',
  BRTS = 'brts',
  DKIM = 'dkim',
  DMARC = 'dmarc',
  ENABLE_QUARANTINE = 'enable_quarantine',
  FASTSPAM_ENABLED = 'fastspam_enabled',
  FASTSPAM_SCORES = 'fastspam_scores',
  I_CAN_HAS_REGION = 'i_can_has_regions',
  INTENT = 'intent',
  INTENT_REALTIME = 'intent_realtime',
  LINKPROTECT = 'linkprotect',
  OUTBOUND_FASTSPAM_ENABLED = 'outbound_fastspam_enabled',
  OUTBOUND_FASTSPAM_SCORES = 'outbound_fastspam_scores',
  OUTBOUND_IP_PARTITION_ID = 'outbound_ip_partition_id',
  OUTBOUND_SPAM_NOTIFICATION = 'outbound_spam_notification',
  OUTBOUND_USE_EMAIL_DOMAIN = 'outbound_use_email_domain',
  ADMINS_MSG_BODY_ACCESS = 'admins_msg_body_access',
  DISABLE_OUTBOUND_MULTILEVEL_INTENT_CONTENT_CHECK = 'disable_outbound_multilevel_intent_content_check',
  PTR = 'ptr',
  QUARANTINE_NOTIFICATION_INTERVAL = 'quarantine_notification_interval',
  REDIRECTOR_CONTENT_ACTION = 'redirector_content_action',
  SPF = 'spf',
  SPF_HARDFAIL = 'spf_hardfail',
  SPF_SOFTFAIL = 'spf_softfail',
  SPF_MISSING = 'spf_missing',
  TYPOSQUAT = 'typosquat',
  VSCAN = 'vscan',
  RETENTION = 'retention',
  OFFICE_MACRO_DETECTION = 'office_macro_detection',
  BULK_EMAIL_DETECTION = 'bulk_email_detection',
  BRTS_SEND_EVIDENCE = 'brts_send_evidence',
  EMAILCAT_CORPORATE = 'emailcat_corporate',
  EMAILCAT_TRANSACTIONAL = 'emailcat_transactional',
  EMAILCAT_MARKETING = 'emailcat_marketing',
  EMAILCAT_LISTSERVER = 'emailcat_listserver',
  EMAILCAT_SOCIALMEDIA = 'emailcat_socialmedia',
  SENDER_SPOOF = 'sender_spoof',
  DEFAULT_SCAN_POLICY = 'default_scan_policy',
  PASSWORD_ARCHIVE_FILTERING = 'password_archive_filtering',
  OUTBOUND_PASSWORD_ARCHIVE_FILTERING = 'outbound_password_archive_filtering',
  PASSWORD_MSOFFICE_FILTERING = 'password_msoffice_filtering',
  OUTBOUND_PASSWORD_MSOFFICE_FILTERING = 'outbound_password_msoffice_filtering',
  PASSWORD_PDF_FILTERING = 'password_pdf_filtering',
  OUTBOUND_PASSWORD_PDF_FILTERING = 'outbound_password_pdf_filtering',
  RBLS = 'rbls',
  MESSAGE_CONTENT_FILTERS = 'message_content_filters',
  MAIL_SERVERS = 'mail_servers',
  CUSTOM_QUARANTINE_NOTIFICATION_INTERVAL = 'custom_quarantine_notification_interval',
  OUTBOUND_QUARANTINE_INTERVAL = 'outbound_quarantine_interval',
  CUSTOM_OUTBOUND_QUARANTINE_INTERVAL = 'custom_outbound_quarantine_interval',
  OUTBOUND_QUARANTINE_EMAIL = 'outbound_quarantine_email',
  SENDER_NOTIFICATION_ENABLE = 'sender_notification_enable',
  SENDER_NOTIFICATION_ADDRESS = 'sender_notification_address',
  SENDER_NOTIFICATION_SUBJECT = 'sender_notification_subject',
  SENDER_NOTIFICATION_TEMPLATE = 'sender_notification_template',
  REJECT_NOTIFICATION_ADDRESS = 'reject_notification_address',
  REJECT_NOTIFICATION_SUBJECT = 'reject_notification_subject',
  REJECT_NOTIFICATION_TEMPLATE = 'reject_notification_template',
  OUTBOUND_SPAM_NOTIFICATION_ADDRESS = 'outbound_spam_notification_address',
  ATD_EXEMPT_EMAIL = 'atd_exempt_email',
  ATD_EXEMPT_IP = 'atd_exempt_ip',
  SPF_EXEMPT = 'spf_exempt',
  SPF_DOMAIN_EXEMPT = 'spf_env_exempt',
  SPF_MISSING_EXEMPT = 'spf_missing_exempt',
  PTR_EXEMPT = 'ptr_exempt',
  DKIM_EXEMPT = 'dkim_exempt',
  DMARC_EXEMPT = 'dmarc_exempt',
  ATD_FILETYPES = 'atd_filetypes',
  ATTACHMENT_FILTER_POLICY = 'attachment_filter_policy',
  OUTBOUND_ATTACHMENT_FILTER_POLICY = 'outbound_attachment_filter_policy',
  DATA = 'data',
  IP_POLICIES = 'ip_policies',
  GEOIP_POLICIES = 'geoip_policies',
  GEOIP_SENDER_NAME_EXEMPT_POLICIES = 'geoip_sender_exempt',
  GEOIP_IP_ADDRESS_EXEMPT_POLICIES = 'geoip_exempt',
  LANGUAGE_POLICIES = 'language_policies',
  USER_POLICIES = 'user_policies',
  RATE_CONTROL = 'rate_control',
  RATE_CONTROL_EXEMPTIONS = 'rate_control_exemptions',
  DISABLE_ACCOUNT_RATE_CONTROL = 'disable_account_rate_control',
  LINKED_DOMAIN_POLICY = 'linked_domain_policy',
  OUTBOUND_TAGLINE_ENABLED = 'outbound_tagline_enabled',
  OUTBOUND_TAGLINE = 'outbound_tagline',
  OUTBOUND_TLS_ALL = 'outbound_tls_all',
  OUTBOUND_TLS_DOMAINS = 'outbound_tls_domains',
  OUTBOUND_TLS_DOMAIN_EXEMPTIONS = 'outbound_tls_domain_exemptions',
  OUTBOUND_SENDER_IP = 'outbound_sender_ip',
  OUTBOUND_PREDEFINED_MESSAGE_CONTENT_FILTERS = 'outbound_predefined_message_content_filters',
  OUTBOUND_PREDEFINED_MESSAGE_CONTENT_FILTERS_EXCEPTIONS = 'outbound_predefined_message_content_filter_exceptions',
  OUTBOUND_MESSAGE_CONTENT_FILTERS = 'outbound_message_content_filters',
  OUTBOUND_PER_DOMAIN_RATE_LIMIT = 'outbound_per_domain_rate_limit',
  OUTBOUND_PER_USER_RATE_LIMIT = 'outbound_per_user_rate_limit',
  TRUSTED_FORWARDERS = 'trusted_forwarders',
  SAVED_SEARCHES = 'saved_searches',
  QUARANTINE_SAVED_SEARCHES = 'quarantine_saved_searches',
  SUPPORT_NOTES = 'support_notes',
  RECENT_SEARCHES = 'recent_searches',
  LINKED_ACCOUNTS = 'linked_accounts',
  MANAGED_DOMAINS = 'managed_domains',
  HELPDESK_DOMAINS = 'helpdesk_domains',
  FORCE_TLS = 'force_tls',
  INBOUND_TLS_ALL = 'inbound_tls_all',
  INBOUND_TLS_DOMAINS = 'inbound_tls_domains',
  INBOUND_TLS_DOMAIN_EXEMPTIONS = 'inbound_tls_domain_exemptions',
  SUPPORT_ACCESS_ENABLED = 'support_access_enabled',
  MESSAGE_BODY_ACCESS_ENABLED = 'message_body_access_enabled',
  EMAIL_BANNERS = 'email_banners',
  EMAIL_BANNERS_TESTING_USERS = 'email_banners_testing_users',
  MACHINE_LEARNING_EXEMPT = 'inference_exempt',
  REACT_OPT_IN = 'react_opt_in',
  REACT_OPT_OUT = 'react_opt_out',
  INLINE_DEPLOYMENT = 'inline_deployment'
}

export enum SettingValue {
  DISABLED = '0',
  ENABLED = '1'
}

export const isSettingValue = (v: any): v is SettingValue => [SettingValue.ENABLED, SettingValue.DISABLED].includes(v)

export enum SettingActionValue {
  BLOCK = '2',
  QUARANTINE = '1',
  OFF = '0'
}

export enum SettingActionValueStringBulkEmail {
  BLOCK = 'block',
  QUARANTINE = 'quarantine',
  OFF = 'off'
}

export enum SettingActionValueString {
  BLOCK = 'blocked',
  QUARANTINE = 'quarantined',
  OFF = 'off'
}

export enum ForceTlsValue {
  NEVER = 'never',
  OPTIONAL = 'optional',
  REQUIRED = 'required'
}

export const DEFAULT_SETTINGS_OBJECT = Object.values(AvailableSettings).reduce((acc: SettingsObject, curr) => {
  acc[curr] = null
  return acc
}, {})

export const emailCategories = [
  {
    name: 'emailcat_corporate_name',
    variable: AvailableSettings.EMAILCAT_CORPORATE,
    default: 'Allow',
    description: 'emailcat_corporate_description',
    recommended: 'allow'
  },
  {
    name: 'emailcat_transactional_name',
    variable: AvailableSettings.EMAILCAT_TRANSACTIONAL,
    default: 'Allow',
    description: 'emailcat_transactional_description',
    recommended: 'allow'
  },
  {
    name: 'emailcat_marketing_name',
    variable: AvailableSettings.EMAILCAT_MARKETING,
    default: 'Off',
    description: 'emailcat_marketing_description',
    recommended: 'off'
  },
  {
    name: 'emailcat_listserver_name',
    variable: AvailableSettings.EMAILCAT_LISTSERVER,
    default: 'Allow',
    description: 'emailcat_listserver_description',
    recommended: 'allow'
  },
  {
    name: 'emailcat_socialmedia_name',
    variable: AvailableSettings.EMAILCAT_SOCIALMEDIA,
    default: 'Allow',
    description: 'emailcat_socialmedia_description',
    recommended: 'allow'
  }
]

export const emailCategoriesActions = [
  {
    label: 'allow',
    value: 'Allow'
  },
  {
    label: 'block',
    value: 'Block'
  },
  {
    label: 'quarantine',
    value: 'Quarantine'
  },
  {
    label: 'off',
    value: 'Off'
  }
]

export interface IpAddressEntry {
  address: string
  netmask: string
  comment?: string
  id: string
  policy?: string
}
export interface DomainEntry {
  domain: string
  comment?: string
  id: string
}

export interface MachineLearningExemptEntry {
  name: string
  comment?: string
  id: string
}

export enum GeoPolicyStatus {
  block = 'block',
  quarantine = 'quarantine'
}

export interface GeoPolicy {
  id: string
  country: string
  policy: string
  comment?: string
}

export interface GeoSenderNameExemptPolicy {
  id: string
  name: string
  comment?: string
}

export interface GeoIPAddressExemptPolicy {
  id: string
  address: string
  netmask: string
  comment?: string
}

export interface LanguagePolicy {
  id: string
  language: string
  policy: string
  comment?: string
}

export enum IpPolicyStatus {
  block = 'block',
  exempt = 'exempt'
}

export interface RecipientPolicy {
  id: string
  name: string
  policy: string
  comment?: string
}

export interface SenderPolicy extends RecipientPolicy {
  modified: number
}

export enum RecipientPolicyStatus {
  scan = 'scan',
  exempt = 'exempt'
}

export enum SenderPolicyStatus {
  block = 'block',
  exempt = 'exempt',
  quarantine = 'quarantine'
}

export enum InlineDeployment {
  MICROSOFT = 'microsoft',
  OFF = 'off'
}

export const AVAILABLE_IP_EXEMPTION_POLICIES = ['block', 'exempt']
export const AVAILABLE_GEO_POLICIES = ['block', 'quarantine']
export const AVAILABLE_RECIPIENT_POLICIES = ['scan', 'exempt']
export const AVAILABLE_SENDER_POLICIES = ['block', 'exempt', 'quarantine']
export const AVAILABLE_ATTACHMENT_FILTER_ACTIONS = ['block', 'ignore', 'quarantine']
export const AVAILABLE_INTENT_DOMAIN_ACTIONS = ['ignore', 'block', 'quarantine']
export const AVAILABLE_MESSAGE_FILTER_ACTIONS = ['block', 'allow', 'quarantine']
export const AVAILABLE_OUTBOUND_MESSAGE_FILTER_ACTIONS = ['block', 'allow', 'quarantine', 'encrypt', 'not_encrypt']
export const AVAILABLE_CPL_OUTBOUND_MESSAGE_FILTER_ACTIONS = ['block', 'allow']
export const AVAILABLE_PREDEFINED_FILTER_PATTERN = ['creditcard', 'ssn', 'privacy', 'hipaa']
export const AVAILABLE_PREDEFINED_FILTER_ACTIONS = ['block', 'quarantine', 'encrypt']
export const AVAILABLE_PREDEFINED_FILTER_EXCEPTION_TYPE = ['phone_number', 'address']
export const AVAILABLE_CPL_PREDEFINED_FILTER_ACTIONS = ['block']
export const AVAILABLE_MESSAGE_CONTENT_FILTER_MATCHES = [
  'subject',
  'headers',
  'body',
  'attachments',
  'sender',
  'recipient'
]
export const AVAILABLE_PREDEFINED_FILTER_MATCHES = ['subject', 'headers', 'body', 'attachments']
export const OUTBOUND_QUARANTINE_INTERVAL_VALUES = ['never', 'immediately', 'custom']

export interface DmarcDkimExempt {
  domain: string
  comment: string
  id: string
}

export interface AttachmentFilter {
  type: string
  pattern: string
  scanArchive: number
  action: string
  minSize: 0 // Could not find a usage in TDF with a different value than 0
  comment: string
  id: string
}

export enum AttachmentFilterType {
  FILENAME = 'filename',
  MIME = 'mime'
}

export enum AttachmentFilterAction {
  BLOCK = 'block',
  IGNORE = 'ignore',
  QUARANTINE = 'quarantine'
}

export enum PredefinedFilterExceptionType {
  PHONE = 'phone_number',
  ADDRESS = 'address'
}

export const AttachmentFilterCheck = [
  {
    label: 'scan',
    value: '1'
  },
  {
    label: 'do_not_scan',
    value: '0'
  }
]

export interface MessageContentFilterMatch {
  subject?: 1
  headers?: 1
  body?: 1
  attachments?: 1
  sender?: 1
  recipient?: 1
}

export interface PredefinedFilterMatch {
  subject?: 1
  headers?: 1
  body?: 1
  attachments?: 1
}

export interface MessageContentFilter {
  pattern: string
  action: string
  match: MessageContentFilterMatch
  id: string
}

export interface PredefinedFilter {
  pattern: string
  action: string
  match: PredefinedFilterMatch
  id: string
}

export interface PredefinedFilterExceptions {
  pattern: string
  type: string
  id: string
}

export enum MessageContentFilterAction {
  BLOCK = 'block',
  ALLOW = 'allow',
  QUARANTINE = 'quarantine'
}

export enum OutboundMessageContentFilterAction {
  BLOCK = 'block',
  ALLOW = 'allow',
  QUARANTINE = 'quarantine',
  ENCRYPT = 'encrypt',
  NOT_ENCRYPT = 'not_encrypt'
}

export enum OutboundPredefinedFilterPattern {
  CREDITCARD = 'creditcard',
  SSN = 'ssn',
  PRIVACY = 'privacy',
  HIPAA = 'hipaa'
}

export enum OutboundPredefinedFilterExceptionType {
  PHONE_NUMBER = 'phone_number',
  ADDRESS = 'address'
}

export enum OutboundPredefinedFilterAction {
  BLOCK = 'block',
  QUARANTINE = 'quarantine',
  ENCRYPT = 'encrypt'
}

export enum MessageContentFilterMatches {
  SUBJECT = 'subject',
  HEADERS = 'headers',
  BODY = 'body',
  ATTACHMENTS = 'attachments',
  SENDER = 'sender',
  RECIPIENT = 'recipient'
}

export enum PredefinedFilterMatches {
  SUBJECT = 'subject',
  HEADERS = 'headers',
  BODY = 'body',
  ATTACHMENTS = 'attachments'
}

export interface IntentDomainPolicy {
  domain: string
  policy: string
  comment: string
  id: string
}

export enum OutboundQuarantineInterval {
  NEVER = 'never',
  IMMEDIATELY = 'immediately',
  CUSTOM = 'custom'
}

export interface PublicAppSettings {
  isEnhancedAuthenticationEnabled: boolean
}
