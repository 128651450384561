import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { saveAs } from '@progress/kendo-file-saver'

import { FpFnType, RedeliverMessage, Search } from 'types/Messages'
import { useAppDispatch, useAppSelector } from 'redux/toolkit/hooks'
import { isPending, isSuccess } from 'redux/toolkit/api'
import {
  deleteMessage,
  downloadMessage,
  getSearch,
  MlogViewConfig,
  MlogViewType,
  postBlockSender,
  postRejectMessages,
  resetDownloadMessage,
  resetSelectedMessage,
  setIsMessageHistoryOpen,
  setRedeliveryQueueMessages
} from 'redux/features/mstore/mstoreSlice'
import * as mstoreLib from 'lib/mstore'
import { StyleProps } from 'components/libs/message/messageDetails/messageDetailsToolbar/messageDetailsToolbarStyles'
import routesConfig from 'lib/routesConfig'
import { getAccountPermissions } from 'redux/features/settings/settingsSlice'
import { useRemediated } from 'components/libs/message/useRemediated'
import { update as updateMessagesTable } from 'redux/features/dataTables/messages/messagesSlice'
import {
  ConfirmRedeliverDialogLogic,
  useConfirmRedeliverDialogLogic
} from 'components/libs/dialog/confirmRedeliver/useConfirmRedeliverDialogLogic'
import { useMessageVariables } from 'components/libs/message/useMessageVariables'
import { stopDeliverReasons } from 'config/filterMaps'
import { useMessageLogRights } from 'components/libs/userRights/pages/useMessageLogRights'
import { trackEventInAllServices, TRACKING_EVENTS } from 'lib/monitoring/monitoringService'
import { useUserDomainIdOverride } from 'lib/hooks/useUserDomainIdOverride'
import { extractEmail } from 'lib/email'

export interface State {
  deleteButton: {
    isDeleteButtonVisible: boolean
  }
  dotsMenu: {
    isDotsMenuEnabled: boolean
    isDotsMenuOpen: boolean
    dotsMenuAnchorElement: RefObject<HTMLButtonElement>
  }
  download: {
    isDownloadMessagePending: boolean
    downloadMessageProgressPercent: number
  }
  emailComposer: {
    isComposerOpen: boolean
    composerEmailType: ComposerEmailType | undefined
  }
  historyButton: {
    messageHistoryButtonTextId: string
  }
  messageDetails: {
    isMessageDetailLinkVisible: boolean
    messageDetailLinkHref: string
  }
  modal: {
    isModalOpen: boolean
  }
  permissions: {
    isDeliverButtonVisible: boolean
    isDeliverDisabled: boolean
    isUserBlocklistDisabled: boolean
    canDownloadMessage: boolean
    isEmergencyInboxEnabled: boolean
    isViewAtpReportsButtonVisible: boolean
    canViewMessageHistory: boolean
  }
  redeliveryDialog: {
    isRedeliveryDialogOpen: boolean
    redeliveryDialogData: any[] | undefined
    isReportButtonVisible: boolean
  }
  reportingModal: {
    isReportingActionModalDisabled: boolean
    isReportingActionModalOpen: boolean
    fpFnType: FpFnType | undefined
    showMarkSpam: boolean
    showMarkHam: boolean
  }
  styleProps: StyleProps
  viewConfig: MlogViewConfig
  confirmRedeliverDialogLogic: ConfirmRedeliverDialogLogic
}

export interface EventHandlers {
  deleteButton: {
    onDeleteMessage: () => void
  }
  reportingModal: {
    onOpenReportingActionModal: (type: FpFnType) => void
    onCloseReportingActionModal: () => void
  }
  historyButton: {
    onToggleMessageHistory: () => void
  }
  dotsMenu: {
    onOpenDotsMenu: () => void
    onCloseDotsMenu: () => void
    onBlockSenderEmail: () => void
    onBlockSenderDomain: () => void
  }
  download: {
    onDownloadMessage: () => void
  }
  emailComposer: {
    onComposerReply: () => void
    onComposerReplyAll: () => void
    onComposerForward: () => void
    onCloseComposer: () => void
  }
  modal: {
    onCloseModal: () => void
  }
  messageDetails: {
    onCloseMessageDetails: () => void
  }
  redeliveryDialog: {
    onOpenRedeliveryDialog: () => void
    onCloseRedeliveryDialog: () => void
  }
  rejectButton: {
    onRejectMessage: () => void
  }
  viewAtpReportButton: {
    onClickViewAtpReports: () => void
  }
}

export type MessageDetailsToolbarLogic = [State, EventHandlers]

export type ComposerEmailType = 'reply' | 'replyAll' | 'forward'

export const useMessageDetailsToolbarLogic = (): MessageDetailsToolbarLogic => {
  const variables = useMessageVariables()
  const dispatch = useAppDispatch()
  const {
    activePath,
    isGetMessagePending,
    isGetMessageSourcePending,
    message,
    accountPermissions,
    searchFilter,
    userId,
    isDownloadMessagePending,
    isDownloadMessageSuccess,
    downloadedMessage,
    downloadMessageProgressPercent,
    isEmergencyInboxEnabled,
    isMessageHistoryOpen,
    selectedMessageMid,
    searchTerm,
    viewConfig,
    userDomainId
  } = useAppSelector(_store => ({
    activePath: _store.app.activePath,
    isGetMessagePending: isPending(_store.mstore.getMessageApiStatus),
    isGetMessageSourcePending: isPending(_store.mstore.getMessageSourceApiStatus),
    message: _store.mstore.message,
    accountPermissions: _store.settings.accountPermissions,
    searchFilter: _store.mstore.searchFilter,
    userId: _store.auth.accessTokenObject?.userId,
    isDownloadMessagePending: isPending(_store.mstore.downloadMessageApiStatus),
    isDownloadMessageSuccess: isSuccess(_store.mstore.downloadMessageApiStatus),
    downloadedMessage: _store.mstore.downloadedMessage,
    downloadMessageProgressPercent: _store.mstore.downloadMessageProgressPercent,
    isEmergencyInboxEnabled: _store.settings.accountPermissions?.emergencyInboxEnabled === 1,
    isMessageHistoryOpen: _store.mstore.isMessageHistoryOpen,
    selectedMessageMid: _store.mstore.selectedMessageMid,
    searchTerm: _store.mstore.searchTerm,
    viewConfig: _store.mstore.viewConfig,
    userDomainId: _store.auth.accessTokenObject?.domainId
  }))
  const overrideDomainId = useUserDomainIdOverride()
  const isMessageDetailLinkVisible = activePath.id !== routesConfig.MESSAGE_DETAIL.id
  const barracudaResponsible = !!message?.barracudaResponsible
  const globalMid = message?.fields.globalMessageId
  const { remediated, remediatedBy, remediatedAt } = useRemediated()
  const [isComposerOpen, setIsComposerOpen] = useState(false)
  const [composerEmailType, setComposerEmailType] = useState<ComposerEmailType>()
  const [isReportingActionModalOpen, setIsReportingActionModalOpen] = useState(false)
  const [isDotsMenuVisible, setIsDotsMenuVisible] = useState(false)
  const [isRedeliveryDialogOpen, setIsRedeliveryDialogOpen] = useState(false)
  const [fpFnType, setFpFnType] = useState<FpFnType>()
  const {
    canBlockMessage,
    canDownloadMessage,
    canOpenMessage,
    canViewAtpReports,
    hasAdminDeliverRights,
    isDeliverableMessage,
    isDeliverConfirmationRequired,
    canDeleteMessage,
    canReplyOrForwardMessage,
    hasRightToSkipSendUserAction,
    canViewMessageHistory
  } = useMessageLogRights()
  const messageDetailLinkHref = useMemo(() => {
    if (!message) {
      return ''
    }
    const remediatedParam = remediated ? 'true' : 'false'
    const remediatedByParam = remediated && remediatedBy ? `&remediated_by=${remediatedBy}` : ''
    const remediatedAtParam = remediated && remediatedAt ? `&remediated_at=${remediatedAt}` : ''
    const search = remediated ? `?remediated=${remediatedParam}${remediatedByParam}${remediatedAtParam}` : ''
    return `${routesConfig.MESSAGE_DETAIL.url({ messageId: message.fields.mid, domainId: message.did })}${search}`
  }, [message, remediated, remediatedAt, remediatedBy])

  const messageHistoryButtonTextId = useMemo(
    () => (isMessageHistoryOpen ? 'menu.hide_history' : 'menu.show_history'),
    [isMessageHistoryOpen]
  )

  const dotsMenuAnchorElement = useRef<HTMLButtonElement>(null)

  const isDeliverButtonVisible = useMemo(() => {
    if (!message || !accountPermissions) {
      return false
    }

    return isDeliverableMessage
  }, [message, accountPermissions, isDeliverableMessage])

  const isDeliverDisabled = useMemo(() => {
    if (hasAdminDeliverRights) {
      return variables.atdMessage || variables.atdPending || variables.atdQuarantine
    }

    if (!message) {
      return false
    }
    // Check if message has a virus or is empty
    if (variables.hasVirus || Number(message.fields.size) === 0) {
      return true
    }

    if (!message.fields.recipientInfo) {
      return true
    }
    // check other reasons message cannot be redelivered for logged-in user
    return message.fields.recipientInfo?.some(
      recipient => recipient.envelopeTo === userId && stopDeliverReasons.indexOf(recipient.reason) !== -1
    )
  }, [hasAdminDeliverRights, message, userId, variables])

  const messageAction = useMemo(() => {
    if (!message) {
      return undefined
    }
    return message.fields.recipientInfo?.[0].action
  }, [message])

  const isReportingActionModalDisabled = useMemo(() => !messageAction, [messageAction])

  const onOpenReportingActionModal = useCallback((type: FpFnType) => {
    const trackEvent =
      type === FpFnType.FN
        ? TRACKING_EVENTS.WEBUI.REPORT_INCORRECTLY_DELIVERED
        : TRACKING_EVENTS.WEBUI.REPORT_INCORRECTLY_BLOCKED
    trackEventInAllServices(trackEvent)
    setIsReportingActionModalOpen(true)
    setFpFnType(type)
  }, [])

  const onCloseReportingActionModal = useCallback(() => {
    setIsReportingActionModalOpen(false)
    setFpFnType(undefined)
  }, [])

  const isQuarantinedSearch = useMemo(() => searchFilter.action.toLowerCase() === 'quarantined', [searchFilter])

  const isQuarantinedForARecipient = useMemo(
    () => !!(message && message.fields.recipientInfo?.some((recipient: any) => recipient.action === 'quarantined')),
    [message]
  )

  const onDeleteMessage = useCallback(() => {
    if (!message) {
      return
    }
    dispatch(
      deleteMessage({
        items: [{ messageId: message.mid, domainId: Number(message.did) }],
        userId: hasRightToSkipSendUserAction ? undefined : userId
      })
    )
  }, [message, dispatch, hasRightToSkipSendUserAction, userId])

  const isDotsMenuEnabled = useMemo(
    () => canBlockMessage || isDeliverButtonVisible,
    [isDeliverButtonVisible, canBlockMessage]
  )

  const isDotsMenuOpen = useMemo(() => isDotsMenuEnabled && isDotsMenuVisible, [isDotsMenuEnabled, isDotsMenuVisible])

  const onOpenDotsMenu = useCallback(() => setIsDotsMenuVisible(true), [])

  const onCloseDotsMenu = useCallback(() => setIsDotsMenuVisible(false), [])

  const onBlockSender = useCallback(
    (emailOrDomain: string, messageId: string, domainId: number) => {
      const blockData = [
        {
          messageId,
          emailOrDomain,
          domainId
        }
      ]
      dispatch(postBlockSender(blockData))
      onCloseDotsMenu()
    },
    [dispatch, onCloseDotsMenu]
  )

  const onBlockSenderEmail = useCallback(() => {
    if (!message) {
      return
    }
    onBlockSender(
      extractEmail(message.fields.headerFrom || message.fields.envelopeFrom),
      message.fields.mid,
      Number(message.did)
    )
  }, [message, onBlockSender])

  const onBlockSenderDomain = useCallback(() => {
    if (!message) {
      return
    }

    const sender = message.fields.headerFrom
      ? extractEmail(message.fields.headerFrom).split('@').pop()
      : message.fields.envelopeFrom.split('@').pop()
    if (!sender) {
      return
    }

    onBlockSender(sender, message.fields.mid, Number(message.did))
  }, [message, onBlockSender])

  const onCloseRedeliveryDialog = useCallback(() => setIsRedeliveryDialogOpen(false), [])

  const onCloseMessageDetails = useCallback(() => {
    dispatch(resetSelectedMessage())
  }, [dispatch])

  const blockedOrQuarantined = useMemo(() => {
    if (!message || !message.fields.recipientInfo) {
      return false
    }
    return message.fields.recipientInfo?.some(
      recipient => recipient.action === 'quarantined' || recipient.action === 'blocked'
    )
  }, [message])

  const isReportButtonVisible = useMemo(
    () => blockedOrQuarantined && barracudaResponsible,
    [barracudaResponsible, blockedOrQuarantined]
  )

  const redeliveryDialogData = useMemo((): RedeliverMessage[] | undefined => {
    if (!message || !isDeliverButtonVisible) {
      return undefined
    }
    return [
      {
        mid: message.fields.mid,
        status: messageAction as any,
        did: Number(message.did) || 0,
        envelopeFrom: message.fields.envelopeFrom,
        headerFrom: message.fields.headerFrom,
        to: message.fields.headerTo,
        subject: message.fields.subject,
        recipientInfo: (message.fields.recipientInfo || []).map(recipient => ({
          envelopeTo: recipient.envelopeTo,
          action: recipient.action,
          reason: recipient.reason
        })),
        didOutbound: message.fields.didOutbound
      }
    ]
  }, [isDeliverButtonVisible, message, messageAction])

  const isModalOpen = useMemo(() => {
    const isComposerVisible = isComposerOpen && composerEmailType
    const isReportingActionModalVisible = isReportingActionModalOpen && messageAction
    return !!(isComposerVisible || isReportingActionModalVisible)
  }, [composerEmailType, isComposerOpen, isReportingActionModalOpen, messageAction])

  const onDownloadMessage = useCallback(() => {
    if (!message) {
      return
    }

    onCloseDotsMenu()
    const domainId = overrideDomainId(message?.did)
    dispatch(
      downloadMessage({
        messageId: message.fields.mid,
        domainId,
        userId: hasRightToSkipSendUserAction ? undefined : userId
      })
    )
  }, [message, onCloseDotsMenu, dispatch, overrideDomainId, hasRightToSkipSendUserAction, userId])

  const onCloseComposer = useCallback(() => setIsComposerOpen(false), [])

  const onOpenComposer = (type: ComposerEmailType) => () => {
    setComposerEmailType(type)
    setIsComposerOpen(true)
  }

  const onCloseModal = useCallback(() => {
    if (isComposerOpen) {
      onCloseComposer()
    }
    if (isReportingActionModalOpen) {
      onCloseReportingActionModal()
    }
  }, [isComposerOpen, isReportingActionModalOpen, onCloseComposer, onCloseReportingActionModal])

  const restoreSearch = useCallback(() => {
    dispatch(getSearch())
  }, [dispatch])

  const dispatchHistorySearch = useCallback(() => {
    if (!message) {
      return
    }
    const historySearch: Search = {
      start: mstoreLib.getStartDate(30),
      end: mstoreLib.getEndDate(),
      offset: 0,
      limit: 0,
      search_str: `global_message_id:${message.fields.globalMessageId}`,
      user_id: searchTerm.user_id,
      domain_id: searchTerm.domain_id,
      outbound: searchTerm.outbound,
      dateRange: 30
    }
    dispatch(getSearch(historySearch))
    dispatch(updateMessagesTable({ skip: 0 }))
  }, [dispatch, message, searchTerm])

  const onToggleMessageHistory = useCallback(() => {
    onCloseDotsMenu()
    dispatch(setIsMessageHistoryOpen(!isMessageHistoryOpen))
    if (isMessageHistoryOpen) {
      restoreSearch()
      return
    }
    dispatchHistorySearch()
  }, [dispatch, dispatchHistorySearch, isMessageHistoryOpen, onCloseDotsMenu, restoreSearch])

  const isDeleteButtonVisible = useMemo(
    () =>
      (canDeleteMessage && viewConfig.type === MlogViewType.OUTBOUND_QUARANTINE) ||
      (isQuarantinedSearch && isQuarantinedForARecipient),
    [isQuarantinedForARecipient, isQuarantinedSearch, viewConfig.type, canDeleteMessage]
  )

  const onRejectMessage = useCallback(() => {
    if (message) {
      dispatch(postRejectMessages([{ messageId: message.mid, domainId: Number(message.did) }]))
    }
  }, [dispatch, message])

  const confirmRedeliverDialogLogic = useConfirmRedeliverDialogLogic()
  const [, confirmDialogEvent] = confirmRedeliverDialogLogic

  const onClickViewAtpReports = useCallback(() => {
    if (message) {
      confirmDialogEvent.handleOpen({ messageId: message.mid, domainId: Number(message.did) })
    }
  }, [confirmDialogEvent, message])

  const onOpenRedeliveryDialog = useCallback(() => {
    if (isDeliverConfirmationRequired) {
      onClickViewAtpReports()
      return
    }
    if (redeliveryDialogData) {
      dispatch(setRedeliveryQueueMessages(redeliveryDialogData))
      setIsRedeliveryDialogOpen(true)
    }
  }, [dispatch, isDeliverConfirmationRequired, onClickViewAtpReports, redeliveryDialogData])

  const showMarkSpam = useMemo(
    () => viewConfig.detailsToolbar.showReport && variables.showMarkSpam,
    [viewConfig, variables]
  )

  const showMarkHam = useMemo(
    () => viewConfig.detailsToolbar.showReport && variables.showMarkHam,
    [viewConfig, variables]
  )

  useEffect(() => {
    if (!selectedMessageMid || !message) {
      return
    }
    dispatch(getAccountPermissions())
  }, [dispatch, selectedMessageMid, message])

  useEffect(() => {
    if (!message || !isDownloadMessageSuccess || !downloadedMessage) {
      return
    }
    const url = window.URL.createObjectURL(new Blob([downloadedMessage.message], { type: 'text/plain;base64' }))
    saveAs(url, `${message.mid}.eml`)
    dispatch(resetDownloadMessage())
  }, [dispatch, downloadedMessage, isDownloadMessageSuccess, message])

  return useMemo(
    () => [
      {
        deleteButton: {
          isDeleteButtonVisible
        },
        dotsMenu: {
          isDotsMenuEnabled,
          isDotsMenuOpen,
          dotsMenuAnchorElement
        },
        download: {
          isDownloadMessagePending,
          downloadMessageProgressPercent
        },
        emailComposer: {
          isComposerOpen,
          composerEmailType
        },
        historyButton: {
          messageHistoryButtonTextId
        },
        messageDetails: {
          isMessageDetailLinkVisible: isMessageDetailLinkVisible && canOpenMessage,
          messageDetailLinkHref
        },
        modal: {
          isModalOpen
        },
        permissions: {
          isDeliverButtonVisible,
          isDeliverDisabled,
          isUserBlocklistDisabled: !canBlockMessage,
          canDownloadMessage,
          isEmergencyInboxEnabled: canReplyOrForwardMessage && isEmergencyInboxEnabled,
          isViewAtpReportsButtonVisible: canViewAtpReports,
          canViewMessageHistory: canViewMessageHistory && !!globalMid
        },
        redeliveryDialog: {
          isRedeliveryDialogOpen,
          redeliveryDialogData,
          isReportButtonVisible
        },
        reportingModal: {
          isReportingActionModalDisabled,
          isReportingActionModalOpen,
          fpFnType,
          showMarkSpam,
          showMarkHam
        },
        styleProps: {
          barracudaResponsible,
          isGetMessagePending,
          isGetMessageSourcePending
        },
        viewConfig,
        confirmRedeliverDialogLogic
      },
      {
        deleteButton: {
          onDeleteMessage
        },
        reportingModal: {
          onOpenReportingActionModal,
          onCloseReportingActionModal
        },
        historyButton: {
          onToggleMessageHistory
        },
        dotsMenu: {
          onOpenDotsMenu,
          onCloseDotsMenu,
          onBlockSenderEmail,
          onBlockSenderDomain
        },
        download: {
          onDownloadMessage
        },
        emailComposer: {
          onComposerReply: onOpenComposer('reply'),
          onComposerReplyAll: onOpenComposer('replyAll'),
          onComposerForward: onOpenComposer('forward'),
          onCloseComposer
        },
        modal: {
          onCloseModal
        },
        messageDetails: {
          onCloseMessageDetails
        },
        redeliveryDialog: {
          onOpenRedeliveryDialog,
          onCloseRedeliveryDialog
        },
        rejectButton: {
          onRejectMessage
        },
        viewAtpReportButton: {
          onClickViewAtpReports
        }
      }
    ],
    [
      showMarkSpam,
      showMarkHam,
      isDeleteButtonVisible,
      isDotsMenuEnabled,
      isDotsMenuOpen,
      isDownloadMessagePending,
      downloadMessageProgressPercent,
      isComposerOpen,
      composerEmailType,
      messageHistoryButtonTextId,
      isMessageDetailLinkVisible,
      canOpenMessage,
      messageDetailLinkHref,
      globalMid,
      isModalOpen,
      isDeliverButtonVisible,
      isDeliverDisabled,
      canBlockMessage,
      isEmergencyInboxEnabled,
      isRedeliveryDialogOpen,
      redeliveryDialogData,
      isReportButtonVisible,
      isReportingActionModalDisabled,
      isReportingActionModalOpen,
      fpFnType,
      barracudaResponsible,
      isGetMessagePending,
      isGetMessageSourcePending,
      viewConfig,
      confirmRedeliverDialogLogic,
      onDeleteMessage,
      onOpenReportingActionModal,
      onCloseReportingActionModal,
      onToggleMessageHistory,
      onOpenDotsMenu,
      onCloseDotsMenu,
      onBlockSenderEmail,
      onBlockSenderDomain,
      onDownloadMessage,
      onCloseComposer,
      onCloseModal,
      onCloseMessageDetails,
      onOpenRedeliveryDialog,
      onCloseRedeliveryDialog,
      onRejectMessage,
      onClickViewAtpReports,
      canDownloadMessage,
      canViewAtpReports,
      canReplyOrForwardMessage,
      canViewMessageHistory
    ]
  )
}
